import css from './AttendeeModal.module.scss'

import { ReactComponent as UserAcceptedIcon } from "assets/modal/user_accepted.svg";
import { ReactComponent as UserDeclinedIcon } from "assets/modal/user_declined.svg";
import { ReactComponent as UserTentativeIcon } from "assets/modal/user_tentative.svg";
import { ReactComponent as UserNeedsActionIcon } from "assets/modal/user_needsAction.svg";


import { Modal } from '@mantine/core';
import { useEffect, useState } from 'react';


const AttendeeModal = ({ opened, setOpened,
    organizer,
    attendees }) => {
    const displayAttendeeResponse = (attendeeResponse) => {
        if (!attendeeResponse) {
            return <UserNeedsActionIcon />;
        }
        switch (attendeeResponse) {
            case "accepted":
                return <UserAcceptedIcon />;

            case "declined":
                return <UserDeclinedIcon />;

            case "tentative":
                return <UserTentativeIcon />;

            default:
                return <UserNeedsActionIcon />;
        }
    };

    const [organizerResponse, setOrganizerResponse] = useState(null);

    useEffect(() => {
        if (attendees && attendees.length > 0) {
            const organizerDeets = attendees.filter((at) => at.email === organizer.email)
            if (organizerDeets[0] && organizerDeets[0].responseStatus) {
                setOrganizerResponse(organizerDeets[0].responseStatus)
            }
            else {
                setOrganizerResponse("needsAction")
            }
        } else {
            setOrganizerResponse("accepted")
        }

    }, [organizer, attendees])

    return (
        <Modal
            overlayOpacity={0.55}
            overlayBlur={3}
            opened={opened}
            onClose={() => setOpened(false)}
            size="30%"
            overflow='inside'
            closeOnClickOutside
>   
            {/* Modal content */}
            <div className={css.wrapper}>
                <div className={css.heading}>Attendees</div>
                {organizer && (
                    <div className={css.container}>
                        <div className={css.attendeeDetail}>
                            <div className={css.avatar}>
                                {displayAttendeeResponse(organizerResponse)}
                            </div>
                            <div>
                                <div>{organizer.email}</div>
                                <span style={{ fontSize: ".7rem", color: 'grey' }}>organizer</span>
                            </div>
                        </div>

                        {attendees.filter((att) => att.email !== organizer.email).map((attendee) => (
                            <div key={attendee.email} className={css.attendeeDetail}>
                                <div className={css.avatar}>
                                    {displayAttendeeResponse(attendee.responseStatus)}
                                </div>
                                <div>
                                    <div>{attendee.email}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </Modal>
    )
}

export default AttendeeModal