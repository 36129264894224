import { Flex, Loader, Text, Title } from "@mantine/core";
import { getNoAndHoursOfMeetings } from "api";
import { useQuery } from "react-query";
import useAnalyticsStore from "stores/useAnalyticsStore";
import { getApiDuration } from "utils/insights";

const HoursCard = ({ asAn, email, type, organizationId, departmentName }) => {
  const { duration } = useAnalyticsStore();
  // fetching hours data
  const { data, isLoading, isError } = useQuery({
    queryKey: ["hours", asAn, type, email, organizationId, departmentName],
    queryFn: () =>
      getNoAndHoursOfMeetings(
        asAn,
        type,
        email,
        organizationId,
        departmentName
      ),
    staleTime: 300000,
  });

  if (isLoading) {
    return (
      <HoursCardWrapper type={type}>
        <div className="loader">
          <Loader />
          <span>Fetching meeting details</span>
        </div>
      </HoursCardWrapper>
    );
  }

  if (isError) {
    return (
      <HoursCardWrapper type={type}>
        <div className="loader">
          <span>Error while fetching meeting details</span>
        </div>
      </HoursCardWrapper>
    );
  }

  if (data.status === false) {
    return (
      <HoursCardWrapper type={type}>
        <div className="loader">
          <span>{data.message}</span>
        </div>
      </HoursCardWrapper>
    );
  }

  return (
    <HoursCardWrapper type={type}>
      <Flex align={"flex-end"} gap={"3.5rem"}>
          <Title order={2}>
            {data.data[getApiDuration(duration)].NoOfMeetings}{" "}
            <Text fz={".9rem"} display={"inline"}>
              Meetings
            </Text>
          </Title>
        </Flex>
      <div>
        <div style={{ fontWeight: "bold", marginBottom: ".4rem" }}>
          <Flex justify={"space-between"}>
            <Text color={"rgba(0, 0, 0, 0.25)"}>Hours of meetings</Text>
            <Text>
              {parseFloat(
                data.data[getApiDuration(duration)].NoOfHoursOfMeetings
              ).toFixed(1)}{" "}
              hrs
            </Text>
          </Flex>
        </div>

        {/* <div style={{ fontWeight: "bold" }}>
          <Flex justify={"space-between"}>
            <Text color={"rgba(0, 0, 0, 0.25)"}>Total Person hours</Text>
            <Text>
              {parseFloat(
                data.data[getApiDuration(duration)].NoOfPeopleHours
              ).toFixed(1)}{" "}
              hrs
            </Text>
          </Flex>
        </div> */}
      </div>
    </HoursCardWrapper>
  );
};

export default HoursCard;

const HoursCardWrapper = ({ type, children }) => {
  return (
    <div className="card">
      <Flex
        direction={"column"}
        gap={"1rem"}
        sx={{ width: "100%" }}
        justify={"space-between"}
      >
        <Title order={5}>
          Meetings {type === "attendee" ? "attended" : "organized"}
        </Title>

        {children}
      </Flex>
    </div>
  );
};
