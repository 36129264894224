import { OrgMeetingDetails } from "api";
import { useQuery } from "react-query";

const useOrgMeetingDetails = ({ orgId, duration }) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["MeetingsOrgTable", orgId, duration],
    queryFn: () => OrgMeetingDetails(orgId, duration),
    refetchOnWindowFocus: false,
    staleTime: 30 * 60 * 1000,
    cacheTime: 60 * 60 * 1000,
  });
  
  return { data, isLoading, isError };
};

export default useOrgMeetingDetails;
