import { createStore } from "zustand/vanilla";

const teamStore = createStore((set) => ({
  team: null,
  setTeam: (team) =>
    set(() => ({
      team,
    })),
  strategicGoals: null,
  setStrategicGoals: (strategicGoals) => set(() => ({ strategicGoals })),
}));

export default teamStore;
