import { Flex, Loader, Text, Title } from "@mantine/core";
import React, { useMemo, useState } from "react";
import ReactECharts from "echarts-for-react";
import EmptyCard from "../EmptyCard/EmptyCard";
import SplitCardModal from "./SplitCardModal";

const SplitCard = ({ data, isLoading, isError, type, title }) => {
  const formattedData = useMemo(
    () =>
      data && Object.keys(data).map((key) => ({ name: key, value: data[key] })),
    [data]
  );

  const option = {
    tooltip: {
      trigger: "item",
    },
    series: [
      {
        name: "In split",
        type: "pie",
        radius: "50%",
        // top: "15%",
        data: formattedData,

        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  if (isLoading) {
    return (
      <Wrapper type={type} title={title}>
        <div className="loader">
          <Loader />
          <span>Fetching goal split</span>
        </div>
      </Wrapper>
    );
  }
  if (isError) {
    return (
      <Wrapper type={type} title={title}>
        <div className="loader">
          <span>Error while fetching tag split</span>
        </div>
      </Wrapper>
    );
  }
  if (Object.keys(data).length === 0) {
    return (
      <Wrapper type={type} title={title}>
        <EmptyCard />
      </Wrapper>
    );
  }

  return (
    <Wrapper type={type} title={title}>
      <ReactECharts
        option={option}
        style={{ height: "100%", width: "100%", alignSelf: "center" }}
        opts={{ renderer: "svg" }}
      />
    </Wrapper>
  );
};

const Wrapper = ({ children, type, title }) => {
  const [modalOpened, setModalOpened] = useState(false)
  return (
    <>
    <Flex direction={"column"} className="card" style={{ height: "16rem" }}>
      <Flex direction={"column"} sx={{ width: "100%" }} h={"100%"}>
        <Flex direction={"column"}>
          <Title order={5}>{title}</Title>
          <Text c="dimmed" fz={"15px"}>
            {type}
          </Text>
        </Flex>
        {children}
      </Flex>
        <Text ml={"auto"} mr={".5rem"} fz={".8rem"} color="blue" underline sx={{cursor: "pointer"}} onClick={()=>setModalOpened(true)}>
          Expand
        </Text>
    </Flex>
    <SplitCardModal
    opened={modalOpened}
    setOpened={setModalOpened}
    title={title}
    >
      {children}
    </SplitCardModal>
    </>
  );
};

export default SplitCard;
