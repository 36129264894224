import { Title } from "@mantine/core";
import { hideNotification } from "@mantine/notifications";
import { truncate } from "lodash";
import { useNavigate } from "react-router-dom";
const InboxNotification = ({ html, task, id }) => {
  const navigate = useNavigate()
  const handleClick= ()=> {
    hideNotification(id)
    navigate("/actions/inbox")
  }
  return (
    <div style={{cursor: "pointer"}} onClick={handleClick}>
      <Title order={6}>From {task?.assigned?.by}</Title>
      <span style={{ fontSize: "12px" }}>You have received an action item</span>
      <br />
      <span style={{ color: "black" }}>{truncate(html, { length: 100 })}</span>
    </div>
  );
};

export default InboxNotification;
