export const theme = {
  colors: {
    pink: [
      "#ffe9ff",
      "#fed1fd",
      "#faa1f7",
      "#f66ef1",
      "#f243eb",
      "#f028e8",
      "#f018e8",
      "#d609cf",
      "#bf00b9",
      "#a700a1",
    ],
    black: [
      "#f5f5f5",
      "#e7e7e7",
      "#cdcdcd",
      "#b2b2b2",
      "#9a9a9a",
      "#8b8b8b",
      "#848484",
      "#717171",
      "#656565",
      "#575757",
    ],
    yellow: [
      "#fff9e1",
      "#fff1cc",
      "#ffe19b",
      "#ffd064",
      "#ffc238",
      "#ffb91c",
      "#ffb509",
      "#e39e00",
      "#ca8c00",
      "#af7800"
    ]
  },
  headings: {
    fontFamily: "inter!important",
  },
  black: '#373B54'
};
