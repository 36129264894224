import { Flex, Text } from "@mantine/core";
import React from "react";
import { HiDocumentSearch } from "react-icons/hi";

const EmptyCard = () => {
  return (
    <Flex
      direction={"column"}
      gap={10}
      align={"center"}
      justify={"center"}
      m={"auto"}
    >
      <HiDocumentSearch size={50} color="#c6c6c6" />
      <Text>No data available</Text>
    </Flex>
  );
};

export default EmptyCard;
