import { fetchMeVsMeeetingForOrg, fetchOrgMeetingEffectiveness, fetchPeopleRated, fetchPersonRated } from "api";
import { useQuery } from "react-query";
import useAnalyticsStore from "stores/useAnalyticsStore";
import { useUser } from "stores/useUser";
import { ONEDAY } from "utils/common";
import { getMyOrgInsights } from "utils/insights";

const useInsights = () => {
  const { user } = useUser();
  const { duration } = useAnalyticsStore();
  //   for my org
  const {
    data: myOrgData,
    isLoading: isMyOrgLoading,
    isError: isMyOrgError,
  } = useQuery({
    queryKey: "MyOrgInsights",
    queryFn: () => getMyOrgInsights(user?.uid),
    staleTime: ONEDAY, // 1 day in milliseconds
  });

  // =================================================================

  //   Me vs meeting (for whole org)
  const {
    data: orgMeVsMeeting,
    isLoading: isOrgMeVsMeetingLoading,
    isError: isOrgMeVsMeetingError,
  } = useQuery({
    queryKey: ["MeVsMeetingWholeOrg", duration, user.email],
    queryFn: () =>
      fetchMeVsMeeetingForOrg(user.subscription.organization_id, duration),
    staleTime: ONEDAY, // 1 day
    retry: 3,
  });


  // ===================================================================

  // effectiveness (for whole org)
  const {
    data : orgEffData,
    isLoading: isOrgEffDataLoading,
    isError: isOrgEffDataError,
  } = useQuery({
    queryKey: ["effectiveness", "whole org", duration, user?.email],
    queryFn: () =>
        fetchOrgMeetingEffectiveness(
            user.subscription.organization_id,
            duration
          ),
    staleTime: ONEDAY
  });


  // effectiveness (for me as attendee)
  const {
    data: meEffDataAttendee,
    isLoading: isMeEffDataAttendeeLoading,
    isError: isMeEffDataAttendeeError,
  } = useQuery({
    queryKey: ["effectiveness", "as attendee", duration, user?.email],
    queryFn: () => fetchPersonRated({userEmail: user?.email, pastDays: duration}),
    staleTime: ONEDAY
  });

    // effectiveness (for me as attendee)
    const {
      data: meEffDataOrganizer,
      isLoading: isMeEffDataOrganizerLoading,
      isError: isMeEffDataOrganizerError,
    } = useQuery({
      queryKey: ["effectiveness", "as organizer", duration, user?.email],
      queryFn: () => fetchPeopleRated({userEmail: user?.email, pastDays: duration}),
      staleTime: ONEDAY
    });

  return {
    myOrgData,
    isMyOrgLoading,
    isMyOrgError,
    orgMeVsMeeting,
    isOrgMeVsMeetingLoading,
    isOrgMeVsMeetingError,
    orgEffData,
    isOrgEffDataLoading,
    isOrgEffDataError,
    meEffDataAttendee,
    isMeEffDataAttendeeLoading,
    isMeEffDataAttendeeError,
    meEffDataOrganizer,
    isMeEffDataOrganizerLoading,
    isMeEffDataOrganizerError,
  };
};

export default useInsights;
