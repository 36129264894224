import {
  Box,
  Card,
  Flex,
  Grid,
  RingProgress,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import React from "react";
import useAnalyticsStore from "stores/useAnalyticsStore";

const TYPES = ["beforeTheDuration", "afterTheDuration"];

const ComparisonCard = ({ data }) => {
  const { duration } = useAnalyticsStore();
  return (
    <Card shadow="sm" p="lg" radius="md">
      <Title order={5}>Comparison</Title>

      <Grid mt={10}>
        {TYPES.map((type, i) => (
          <Grid.Col span={6} key={i}>
            <Stack spacing={10}>
              <Text fw={500}>
                {type === "beforeTheDuration" ? "Before" : "After"} {duration}{" "}
                days
              </Text>
              <Flex gap={20}>
                <RingProgress
                  sections={[
                    {
                      value: data?.[type]?.pending_percentage,
                      color: "yellow.5",
                      tooltip: (
                        <Text>
                          Pending tasks: {data?.[type]?.pending_percentage}%
                        </Text>
                      ),
                    },
                    {
                      value: data?.[type]?.completed_percentage,
                      color: "#02B9DA",
                      tooltip: (
                        <Text>
                          Completed tasks: {data?.[type]?.completed_percentage}%
                        </Text>
                      ),
                    },
                  ]}
                />

                {/* summary */}
                <Stack spacing={12}>
                  <Text fz={"md"} fw={500} c={"black.8"}>
                    Total action items: {data?.[type]?.total}
                  </Text>

                  {/* completed */}
                  <Flex align={'center'} gap={12}>
                    <Box
                      component="div"
                      sx={{
                        borderRadius: 99,
                        height: "10px",
                        width: "10px",
                      }}
                      bg={'#02B9DA'}
                    />
                    <Text>
                        Completed: {data?.[type]?.completed}
                    </Text>
                  </Flex>

                  {/* pending */}
                  <Flex align={'center'} gap={12}>
                    <Box
                      component="div"
                      sx={{
                        borderRadius: 99,
                        height: "10px",
                        width: "10px",
                      }}
                      bg={'yellow.5'}
                    />
                    <Text>
                        Pending: {data?.[type]?.pending}
                    </Text>
                  </Flex>
                </Stack>
              </Flex>
            </Stack>
          </Grid.Col>
        ))}
      </Grid>
    </Card>
  );
};

export default ComparisonCard;
