import { create } from "zustand";
const useWorkspaceStore = create((set) => ({
  isLoading: false,
  isMetaDataLoading: false,
  isError: false,
  isMetaDataError: false,
  selectedDate: new Date(),
  events: [],
  metaData: [],
  selectedEventId: null,
  setSelectedDate: (data) =>
    set(() => ({
      selectedDate: data,
    })),
  setEvents: (data) => set(() => ({ events: data })),
  setMetaData: (data) => set(() => ({ metaData: data })),
  setSelectedEventId: (data) => set(() => ({ selectedEventId: data })),
  setLoading: (status) => set(() => ({ isLoading: status })),
  setMetaDataLoading: (status) => set(() => ({ isMetaDataLoading: status })),
  setError: (status) => set(() => ({ isError: status })),
  setMetaDataError: (status) => set(() => ({ isMetaDataError: status })),
  updateMetaData: (type, eventId, data) => set((state) => {

    const { metaData } = state;
    let updatedMetaData = [...metaData]
    const index = updatedMetaData.findIndex((item) => item.id === eventId);
    if (type === "rating") {
      index !== -1 ?
        updatedMetaData[index] = { ...updatedMetaData[index], rating: data }
        :
        updatedMetaData = [...updatedMetaData, { id: eventId, rating: data }]
    }
    else if (type === "feedback") {
      index !== -1 ?
        updatedMetaData[index] = { ...updatedMetaData[index], feedback: data } :
        updatedMetaData = [...updatedMetaData, { id: eventId, feedback: data }]
    }
    else if (type === "tag") {
      index !== -1 ?
        updatedMetaData[index] = { ...updatedMetaData[index], tag: data } :
        updatedMetaData = [...updatedMetaData, { id: eventId, tag: data }]
    }
    else if (type === 'hasAttended') {
      index !== -1 ?
        updatedMetaData[index] = { ...updatedMetaData[index], hasAttended: data } :
        updatedMetaData = [...updatedMetaData, { id: eventId, hasAttended: data }]
    }


    return { metaData: updatedMetaData }

  })
}));


export default useWorkspaceStore;
