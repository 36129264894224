import { Button, Modal } from "@mantine/core";
import css from "./InviteModal.module.scss";
import Screen from "assets/main-screen-gt.png";
import Logo from "assets/white-logo.png";
import InviteInput from "./InviteInput/InviteInput";
import { useState } from "react";
import { notify } from "utils/common";
import { useUser } from "stores/useUser";
import { sendInvitationsToEmails } from "api";

const InviteModal = ({ opened, setOpened }) => {
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useUser();

  const changeHandler = (emails) => {
    const to_Emails = emails.map((email) => { return email.toLowerCase() })
    if (to_Emails.includes(user?.email)) {
      notify("You can't invite yourself", "error")
    } else {
      setEmails(to_Emails)
    }
  }

  const sendInvitesToEmails = async () => {
    try {
      setLoading(true)
      const res = await sendInvitationsToEmails(user?.uid, user?.email, emails);
      if (res?.status) {
        notify("Invitations sent Succesfully", "success")
        setLoading(false)
        setOpened(false)
        setEmails([])
      } else {
        notify(res?.message, "error")
        setLoading(false)
      }
    } catch (err) {
      setLoading(false)
      notify("Faied to send Invitations", "error")
    }
  }

  return (
    <Modal
      opened={opened}
      onClose={() => {
        setOpened(false)
        setEmails([])
      }}
      className={css.modal}
      centered
    >
      <div className={css.container}>
        <div className={css.tiltGradientContainer} />
        <div className={css.left}>
          <img src={Logo} alt="logo" className={css.logo} />
          <div className={css.typography}>
            <span>Invite coworkers to your workspace</span>
            <span>
              Collaborate with your teammates and easily share meeting notes,
              action items and insights
            </span>
          </div>
          <InviteInput emails={emails} changeHandler={changeHandler} />

          <Button disabled={emails.length === 0}
            loading={loading}
            onClick={sendInvitesToEmails}
          >Invite</Button>
        </div>
        <div className={css.right}>
          <img src={Screen} alt="screenshot" />
        </div>
      </div>
    </Modal>
  );
};

export default InviteModal;
