import { Flex, Loader, Text, Title } from "@mantine/core";
import React, { useState } from "react";
import EmptyCard from "../EmptyCard/EmptyCard";
import CustomFeedbackModal from "./CustomFeedbackModal";
const CustomFeedbackCard = ({ data, isLoading, isError }) => {
  const [modalOpened, setModalOpened] = useState(false);
  if (isLoading) {
    return (
      <Wrapper>
        <div className="loader">
          <Loader />
          <span>Analysing feedbacks</span>
        </div>
      </Wrapper>
    );
  }
  if (isError) {
    return (
      <Wrapper>
        <div className="loader">
          {data?.message || <span>Error while fetching feedbacks</span>}
        </div>
      </Wrapper>
    );
  }

  if (data?.length === 0) {
    return (
      <Wrapper>
        <div className="loader">
          <EmptyCard />
        </div>
      </Wrapper>
    );
  }

  return (
    <>
      <Wrapper>
        <Flex direction={"column"} gap={".8rem"}>
          {data?.slice(0, 5)?.map((feedback, i) => (
            <Text key={i}>* {feedback?.feedback}</Text>
          ))}
        </Flex>
        {data?.length > 0 && (
          <Text
            ml={"auto"}
            underline
            color="blue"
            sx={{ cursor: "pointer" }}
            onClick={() => setModalOpened(true)}
          >
            See more
          </Text>
        )}
      </Wrapper>
      <CustomFeedbackModal data={data} opened={modalOpened} setOpened={setModalOpened} />
    </>
  );
};

const Wrapper = ({ children }) => {
  return (
    <div className="card">
      <Flex
        direction={"column"}
        sx={{ width: "100%" }}
        justify={"space-between"}
      >
        <Flex direction={"column"}>
          <Title order={5}>Custom Feedbacks</Title>
        </Flex>
        {children}
      </Flex>
    </div>
  );
};

export default CustomFeedbackCard;
