import { Tabs } from "@mantine/core";
import React, { useState } from "react";
import css from "./OrgTabs.module.scss";
import Organization from "./Organization";
import MyOrg from "./MyOrg";
const OrgTabs = () => {
  const [activeTab, setActiveTab] = useState("first");

  return (
    <Tabs mt="1rem" value={activeTab} onTabChange={setActiveTab}>
      <Tabs.List className={css.tabsHeader}>
        <Tabs.Tab className={css.tab} value="first">
          My organization
        </Tabs.Tab>
        <Tabs.Tab className={css.tab} value="second">
          Whole oganization
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="first" className={css.tabDetail}>
        <MyOrg />
      </Tabs.Panel>
      <Tabs.Panel value="second" className={css.tabDetail}>
        <Organization />
      </Tabs.Panel>
    </Tabs>
  );
};

export default OrgTabs;
