import { actionsSummary } from 'api';
import { useQuery } from 'react-query';

const useActionsSummary = ({userId, duration}) => {
    const { data, isLoading, isError } = useQuery({
        queryKey: ["ActionSummary", userId, duration],
        queryFn: () => actionsSummary(userId, duration),
        refetchOnWindowFocus: false,
        staleTime: 30 * 60 * 1000,
        cacheTime: 60 * 60 * 1000,
      });
  return (
    {data, isLoading, isError}
  )
}

export default useActionsSummary