import { Flex, Loader, Text, Title } from "@mantine/core";
import React from "react";
import { formatNumber } from "utils/common";
import { getComparisonColor } from "utils/insights";
import EmptyCard from "../EmptyCard/EmptyCard";

const CostCard = ({
  type,
  isLoading,
  isError,
  data,
  label = "Cost of meetings",
}) => {
  if (isLoading) {
    return (
      <CostCardWrapper type={type} label={label} data={data}>
        <div className="loader">
          <Loader />
          <span>Fetching cost details</span>
        </div>
      </CostCardWrapper>
    );
  }

  if (isError) {
    return (
      <CostCardWrapper type={type} label={label} data={data}>
        <div className="loader">
          <span>{isError}</span>
        </div>
      </CostCardWrapper>
    );
  }
  if (!data) {
    return (
      <CostCardWrapper label={label}>
        <div className="loader">
          <EmptyCard />
        </div>
      </CostCardWrapper>
    );
  }
  
  return (
    <CostCardWrapper type={type} label={label} data={data}>
      <Flex align={"flex-end"} gap={"3.5rem"} justify={"space-between"}>
        <Title order={2}>${formatNumber(data?.totalMeetingsCost)}</Title>
        <Title order={6} color={getComparisonColor(data?.comparisonPercentage)}>
          {parseFloat(data?.comparisonPercentage).toFixed(1) !== "0.0"
            ? parseFloat(data?.comparisonPercentage) > 0 && "+"
            : null}
          {parseFloat(data?.comparisonPercentage).toFixed(1) > 999
            ? "999"
            : parseFloat(data?.comparisonPercentage).toFixed(1)}{" "}
          %
          {parseFloat(data?.comparisonPercentage).toFixed(1) !== "0.0"
            ? parseFloat(data?.comparisonPercentage) > 0
              ? " more"
              : " less"
            : null}
        </Title>
      </Flex>
      <div>
        <div style={{ fontWeight: "bold", marginBottom: ".4rem" }}>
          <Flex justify={"space-between"}>
            <Text color={"rgba(0, 0, 0, 0.25)"}>External Meetings</Text>
            <Text>${formatNumber(data?.externalMeetingsCost)}</Text>
          </Flex>
        </div>

        <div style={{ fontWeight: "bold" }}>
          <Flex justify={"space-between"}>
            <Text color={"rgba(0, 0, 0, 0.25)"}>Internal Meetings</Text>
            <Text>${formatNumber(data?.internalMeetingsCost)}</Text>
          </Flex>
        </div>
      </div>
    </CostCardWrapper>
  );
};

export default CostCard;

const CostCardWrapper = ({
  type,
  children,
  data,
  label = "Cost of meetings",
}) => {
  return (
    <div className="card">
      <Flex
        direction={"column"}
        gap={"1rem"}
        sx={{ width: "100%" }}
        justify={"space-between"}
      >
        <Title order={5}>
          {label}{" "}
          {type === "attendee"
            ? "attended"
            : type === "organizer"
            ? "organized"
            : ""}
        </Title>
        {data?.totalMeetingsCost !== null ? children : <EmptyCard />}
      </Flex>
    </div>
  );
};
