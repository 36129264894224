import { Flex, Modal} from "@mantine/core";
import React from "react";

const SplitCardModal = ({ opened, setOpened, children, title }) => {
  return (
    <Modal
      opened={opened}
      onClose={() => setOpened(false)}
      closeOnClickOutside
      overlayOpacity={0.55}
      overlayBlur={3}
      size={"50%"}
      centered
      title={title}
    >
      <Flex align="center" justify="center" h={"30rem"}>
        {children}
      </Flex>
    </Modal>
  );
};

export default SplitCardModal;
