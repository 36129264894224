import { Badge } from "@mantine/core";
import Tooltip from "components/Tooltip/Tooltip";
import React, { useMemo } from "react";
import { changeAmoutToDollar } from "utils/events";

const Cost = ({ calendarEvent, start, end }) => {

  const {toolTipMsg, backgroundColor, dollarSign} = useMemo(() => {
    const durationMillis = new Date(end) - new Date(start);
    const durationHours = durationMillis / 1000 / 60 / 60;
    const numAttendees = calendarEvent?.attendees?.length || 0;
    const cost = (numAttendees * 100) * durationHours;

    return changeAmoutToDollar(cost);
  }, [calendarEvent, start, end]);

  return (
    <Tooltip
      style={{
        boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.4)",
      }}
      label={toolTipMsg}
      color="#ededed"
      multiline
      width={200}
      withArrow
      position="bottom"
      arrowSize={6}
    >
      <Badge
        size="lg"
        sx={{
          background: backgroundColor,
          color: "black",
          cursor: "pointer"
        }}
      >
        <span>{dollarSign}</span>
      </Badge>
    </Tooltip>
  );
};

export default Cost;
