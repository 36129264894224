import { FocusTrap, Select } from "@mantine/core";
import { uniq } from "lodash";
import React from "react";

const TagSelectBox = ({ tagOptions, handleOnClick, setOpened }) => {
  return (
    <FocusTrap active>
      <Select
        label="Available tags"
        placeholder="Pick one"
        searchable
        nothingFound="No options"
        data={uniq(tagOptions?.map((option) => option?.tag))}
        onChange={(value) => {
          handleOnClick(value);
          setOpened(false);
        }}
      />
    </FocusTrap>
  );
};

export default TagSelectBox;
