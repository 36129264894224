import { Button, Flex, Rating, Text } from "@mantine/core";
import React from "react";
import { useUser } from "stores/useUser";
import css from "./WithPremium.module.scss";
import { TbDiamondFilled } from "react-icons/tb";
import Tooltip from "components/Tooltip/Tooltip";
import { AiFillDollarCircle, AiFillTag } from "react-icons/ai";
import { FiSlack } from "react-icons/fi";

const WithPremium = ({ feature, children }) => {
  const {
    user: {
      subscription: { plan },
    },
  } = useUser();
  if (plan.toUpperCase() === "INDIVIDUAL") {
    switch (feature) {
      case "rating":
        return <PremiumRating />;

      case "slack":
        return <PremiumSlack />;

      case "insights":
        return <PremiumInsights />;
      default:
        break;
    }
  } else return <>{children}</>;
};

export default WithPremium;

const PremiumRating = () => {
  return (
    <div className={css.rating}>
      <Tooltip label={"Rate your meetings with upgraded plan"}>
        <Rating readOnly />
      </Tooltip>
      <Tooltip label={"See cost of meetings with upgraded plan"}>
        <Flex align={"center"}>
          <AiFillDollarCircle size={20} color="var(--blue)" />
          <Text>$1...</Text>
        </Flex>
      </Tooltip>
      <Tooltip label={"Tag your meetings with upgraded plan"}>
        <Flex align={"center"}>
          <AiFillTag size={20} color="var(--blue)" />
          <Text>???</Text>
        </Flex>
      </Tooltip>
      <TbDiamondFilled size={20} color="var(--golden)" />
    </div>
  );
};

const PremiumSlack = () => {
  return (
    <Tooltip
      label={
        <span>
          Upgrade plan to send notes directly to your Slack{" "}
          <TbDiamondFilled color="var(--golden)" />
        </span>
      }
    >
      <div>
        <Button
          variant="outline"
          color="yellow"
          compact
          leftIcon={<FiSlack />}
          rightIcon={<TbDiamondFilled />}
        >
          Slack
        </Button>
      </div>
    </Tooltip>
  );
};

const PremiumInsights = () => {
  return (
    <Tooltip label={"Upgrade to a premium plan to see Insights!"} width={"20rem"}>
      <div className={css.insights}>
        <span>Insights</span>
        <TbDiamondFilled color="var(--golden)" size={20} />
      </div>
    </Tooltip>
  );
};
