import { find, groupBy } from "lodash";
import moment from "moment/moment";
import { notify } from "./common";

// at workspace page, calendar highlight the date if it is today's date
export const highlightToday = (date) => {
  return moment(date).format("DD-MM-YYYY") === moment().format("DD-MM-YYYY");
};

export const getFormattedDate = (dateString) => {
  const formattedDate = moment(dateString).format("ddd, D MMM YYYY");
  return formattedDate;
};

export const getEventCallLink = (calendarEvent) => {
  const conferenceData = calendarEvent.conferenceData;

  if (!conferenceData) {
    return null;
  }

  const callLink = conferenceData?.entryPoints?.find(
    (o) => o.entryPointType === "video"
  );

  if (callLink) return callLink.uri;
  else return null;
};

export const getUserEventResponse = (calendarEvent, user) => {
  // responseStatus: "accepted" | "declined" | "needsAction" | "tentative" (maybe)

  const user_email = user?.email;
  const { organizer, attendees } = calendarEvent;
  try {
    // if (organizer?.email === user_email) {
    //   return "accepted";
    // }

    if (attendees?.length > 0) {
      const currentUser = find(attendees, { email: user_email });
      if (currentUser?.responseStatus) return currentUser.responseStatus;
    } else {
      if (organizer?.email === user_email) {
        return "accepted";
      }
    }

    return "needsAction";
  } catch (err) {
    console.error(err.message);
    return "needsAction";
  }
};

export const checkForIncoming = (selectedEvent, hasAttended) => {
  if (!(selectedEvent?.hasEventEnded || selectedEvent?.isOngoing)) {
    notify("You can't rate an upcoming Event", "info");
    return;
  }
  if (hasAttended === false) {
    notify("You can't rate an event you didn't attend", "info");
    return;
  }
};

export const parseFetchedEvents = (user, events) => {
  if (!events?.length) {
    return [];
  }

  let parsedData = events
    .map((event) => {
      if (event?.kind !== "calendar#event") {
        return null;
      }
      const response = getUserEventResponse(event, user);
      if (response === "declined") {
        return null;
      }

      const eventId = event.id;
      const title = event.summary || null;
      const description = event.description || null;
      const date = getFormattedDate(event.start.dateTime);
      const startTime = moment(event.start.dateTime).format("h:mma");
      const startDateTime = moment(event.start.dateTime).valueOf();
      const endTime = moment(event.end.dateTime).format("h:mma");
      const endDateTime = moment(event.end.dateTime).valueOf();
      const callLink = getEventCallLink(event);
      const organizer = event.organizer;
      const attendees = event.attendees;
      const hasEventEnded = moment(event.end.dateTime).isBefore(moment());
      const isOngoing = moment().isBetween(
        moment(event.start.dateTime),
        moment(event.end.dateTime)
      );
      const recurringEventId = event.recurringEventId;
      return {
        eventId,
        title,
        startTime,
        endTime,
        response,
        callLink,
        startDateTime,
        endDateTime,
        isOngoing,
        date,
        description,
        organizer,
        attendees,
        hasEventEnded,
        recurringEventId,
      };
    })
    .filter((event) => event !== null);

  const firstOngoingEvent = parsedData.find((event) => event.isOngoing);
  const firstUpcomingEvent = parsedData.find(
    (event) => !event.hasEventEnded && !event.isOngoing
  );

  if (firstOngoingEvent || firstUpcomingEvent) {
    parsedData = parsedData.map((event) => {
      const isFirstOngoingEvent = event.eventId === firstOngoingEvent?.eventId;
      const isFirstUpcomingEvent =
        event.eventId === firstUpcomingEvent?.eventId;

      return { ...event, isFirstOngoingEvent, isFirstUpcomingEvent };
    });
  }

  return parsedData;
};

export const getCallLink = (callLink, setUpdatedCallLink) => {
  if (callLink) {
    let cc = callLink;
    if (callLink.includes("zoom.us")) {
      const matches1 = callLink.match(/(.*)zoom.us\/j\/(.*)\?pwd=(.*)/);
      if (matches1) {
        const confno = matches1[2];
        const pwd = matches1[3];
        cc = `zoommtg://zoom.us/join?action=join&confno=${confno}&pwd=${pwd}`;
      } else {
        const matches2 = callLink.match(/(.*)zoom.us\/j\/(.*)/);
        const confno = matches2[2];
        cc = `zoommtg://zoom.us/join?action=join&confno=${confno}`;
      }

      setUpdatedCallLink(cc);
    } else if (callLink.includes("https://meet.google.com")) {
      setUpdatedCallLink(callLink);
    }
  }
};

export const feedbackTagOptions = [
  "Could have been an email",
  "Multi-tasking",
  "Right attendees were not present",
  "No Agenda",
  "My Presence was not relevant",
  "Meeting ran over",
  "Poorly facilicated",
  "I wasn't given the opportunity to contribute",
  "Too large to foster good dialog",
  "Conference call made it difficult for phone participants",
];

export const changeAmoutToDollar = (amount) => {
  let dollarSign = "",
    toolTipMsg = "",
    backgroundColor = "";
  if (amount < 500) {
    dollarSign = "$";
    toolTipMsg = "The approx. cost of this meeting is less than $500.";
    backgroundColor = "rgb(232 216 250)";
  } else if (amount < 1000) {
    dollarSign = "$$";
    toolTipMsg = "The approx. cost of this meeting is between $500 and $1,000.";
    backgroundColor = "#c5ffbe";
  } else if (amount < 5000) {
    dollarSign = "$$$";
    toolTipMsg =
      "The approx. cost of this meeting is between $1,000 and $5,000.";
    backgroundColor = "#ffee94";
  } else if (amount < 10000) {
    dollarSign = "$$$$";
    toolTipMsg =
      "$The approx. cost of this meeting is between $5,000 and $10,000.";
    backgroundColor = "#ffbd94";
  } else if (amount > 10000) {
    dollarSign = "$$$$$";
    toolTipMsg = "The approx. cost of this meeting is more than $10,000.";
    backgroundColor = "#ff5959";
  } else {
    dollarSign = "";
    toolTipMsg = "";
    backgroundColor = "#d8ecfa";
  }
  return {
    dollarSign,
    toolTipMsg,
    backgroundColor,
  };
};

export const groupEventsByDate = (events) => {
  const eventsGroupedByDate = groupBy(events, (event) =>
    moment(event?.start).format("DD/MMM/YYYY")
  );
  return eventsGroupedByDate;
};

export const getGoal = (strategicGoals, selectedEvent, setGoal) => {
  let goalFounded = false;
  Object.keys(strategicGoals).some((goal) => {
    strategicGoals?.[`${goal}`]?.events?.some((id) => {
      if (
        id === selectedEvent?.eventId ||
        id === selectedEvent?.recurringEventId
      ) {
        // console.log("founded goal: " + goal);
        goalFounded = true;
        setGoal(goal);
        return true;
      }
      return false;
    });
  });
  if (!goalFounded) {
    setGoal(null);
  }
};

export const eventColorsWrtTag = {
  "1:1 with Colleague": "#22C55E",
  "1:1 with DR": "#22C55E",
  "1:1 with Mgr": "#22C55E",
  "Incident mgmt": "#8E33FF",
  "Staff call": "#8E33FF",
  "Town Hall": "#8E33FF",
  Interview: "#8E33FF",
  "Presentation prep": "#8E33FF",
  "Office hours": "#8E33FF",
  "Planning (LRP, Budgeting, V2MOM)": "#8E33FF",
  "Feature development": "#FFAB00",
  "Project Initiative work": "#FFAB00",
  "Customer facing (non-inci)": "#FFAB00",
};
