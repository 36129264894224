import { useAutoAnimate } from "@formkit/auto-animate/react";
import { Flex, ScrollArea, Skeleton, Text } from "@mantine/core";
import { useScrollIntoView } from "@mantine/hooks";
import DateHandler from "components/Events/DateHandler";
import EventList from "components/Events/EventList";
import useCalendar from "hooks/useCalendar";
import { cloneDeep, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { groupEventsByDate } from "utils/events";

const Events = () => {
  const {
    activeDateRange,
    events,
    metaData,
    setActiveDateRange,
    metaDataLoading,
    loading,
    error,
    metaDataError,
    refreshMetaData,
  } = useCalendar();

  const [groupedEvents, setGroupedEvents] = useState({});
  const [parent] = useAutoAnimate(/* optional config */);
  const { scrollIntoView, targetRef } = useScrollIntoView({
    offset: 60,
  });
  useEffect(() => {
    if (events.length > 0) {
      setGroupedEvents(() => groupEventsByDate(events));
    } else {
      setGroupedEvents({});
    }
  }, [events]);

  const refresh = () => {
    let updatedDate = cloneDeep(activeDateRange);
    setActiveDateRange(updatedDate);
  };

  return (
    <Flex
      justify={"center"}
      width="100%"
      px={"2rem"}
      py={"1.5rem"}
      sx={{ maxWidth: "100rem", margin: "auto", backgroundColor: "#80808008" }}
      direction={"column"}
      gap={"2rem"}
    >
      {/* date selector */}
      <DateHandler
        activeDateRange={activeDateRange}
        setActiveDateRange={setActiveDateRange}
        scrollIntoView={scrollIntoView}
      />

      <ScrollArea w={"100%"} h={"calc(100vh - 11.55rem)"}>
        <Flex
          w={"100%"}
          direction={"column"}
          gap={"2.5rem"}
          px={"1rem"}
          ref={parent}
        >
          {!loading
            ? Object.keys(groupedEvents).map((date, i) => (
                <EventList
                  key={i}
                  data={groupedEvents[date]}
                  date={date}
                  metaData={metaData}
                  refresh={refresh}
                  metaDataLoading={metaDataLoading}
                  targetRef={targetRef}
                  refreshMetaData={refreshMetaData}
                />
              ))
            : Array.from(new Array(5)).map((item, i) => (
                <Skeleton height={80} radius="md" mt={10} key={i} />
              ))}
          {error && <Text color="red">Error fetching events</Text>}
          {metaDataError && <Text color="red">Error fetching metadata</Text>}
          {isEmpty(groupedEvents) && !loading && (
            <Text>No events found for this date range</Text>
          )}
        </Flex>
      </ScrollArea>
    </Flex>
  );
};

export default Events;
