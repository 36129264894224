import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { RequireAuth } from "utils/RequireAuth";
import { useUser } from "stores/useUser";
import useExtensioniFrameListener from "hooks/useExtensioniFrameListener";
import Events from "pages/Events/Events";
import OrgTabs from "pages/Insights/pages/Organizatoin/OrgTabs";
import ActionsInsights from "pages/Insights/pages/ActionsInsights/ActionsInsights";

const Analytics = lazy(() => import("pages/Insights/pages/Analytics/Analytics"));
const DirectReport = lazy(() => import("pages/Insights/pages/DirectReportees/DirectReport/DirectReport"));
const DirectReportees = lazy(() => import("pages/Insights/pages/DirectReportees/DirectReportees"));
const AsAttendee = lazy(() => import("pages/Insights/pages/MeetingTime/AsAttendee/AsAttendee"));
const AsOrganizer = lazy(() => import("pages/Insights/pages/MeetingTime/AsOrganizer/AsOrganizer"));
const PrivacyPolicy = lazy(() => import("pages/PrivacyPolicy/PrivacyPolicy"));
const TermsOfUse = lazy(() => import("pages/TermsOfUse/TermsOfUse"));
const Waitlist = lazy(() => import("pages/Waitlist/Waitlist"));
const Fallback = lazy(() => import("components/FallBack/Fallback"));
const MeTime = lazy(() => import("pages/Insights/pages/MeetingTime/MeTime"));


const ActionsPage = lazy(() => import("pages/Actions/ActionsPage"));
const WorkspacePage = lazy(() => import("pages/Workspace/WorkspacePage"));
const CalendarPage = lazy(() => import("pages/Calendar/CalendarPage"));
const InsightsLayout = lazy(() => import("pages/Insights/InsightsLayout"));
const LoginPage = lazy(() => import("pages/Login/LoginPage"));
const PricingPage = lazy(() => import("pages/Pricing/PricingPage"));
const InvitationPage = lazy(() => import("pages/Invites/InvitationPage"));
const ExtensionEventPage = lazy(() =>
  import("extension/views/Meeting/Meeting")
);
const ExtensionAgendaPage = lazy(() => import("extension/views/Agenda/Agenda"));

const AllRoutes = () => {
  const { user } = useUser();
  useExtensioniFrameListener();

  return (
    <>
      <Suspense fallback={<Fallback />}>
        <Routes>
          {/* public routes */}
          <Route path="/waitlist" element={<Waitlist />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/subscription" element={<PricingPage />} />
          <Route path="/invite" element={<InvitationPage />} />

          {/* private routes */}
          <Route element={<RequireAuth />}>
            <Route
              path="/extension/event/:eventId"
              element={<ExtensionEventPage />}
            />
            <Route path="/extension/agenda" element={<ExtensionAgendaPage />} />
            <Route path="/workspace" element={<WorkspacePage />} />
            <Route path="/actions" element={<ActionsPage />}>
              <Route path=":type"/>
            </Route>
            <Route path="/calendar" element={<CalendarPage />} />
            <Route path="/events" element={<Events />} />
            <Route path="/insights" element={<InsightsLayout />}>
              <Route path="analytics" element={<Analytics />} />
              <Route path="" element={<Navigate push to="me-time/organizer" />} />
              <Route path="me-time" >
                <Route index element={<MeTime/>} />
                <Route
                  path="organizer"
                  element={<AsOrganizer email={user?.email} />}
                />
                <Route
                  path="attendee"
                  element={<AsAttendee email={user?.email} />}
                />
              </Route>
              <Route path="direct-reportees">
                <Route index element={<DirectReportees />} />
                <Route path=":id" element={<DirectReport />} />
              </Route>
              <Route path="actions-insights" element={<ActionsInsights />}/>
              <Route path="organization" element={<OrgTabs />} />
            </Route>
          </Route>

          {/* Default route */}
          <Route path="*" element={<Navigate push to="/workspace" />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default AllRoutes;
