import React, { useEffect, useState } from "react";
import css from "../MeetingTime/AsAttendee/AsAttendee.module.scss";
import CostCard from "components/Insights/CostCard/CostCard";
import { useUser } from "stores/useUser";
import {
  collection,
  documentId,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { FirestoreDB } from "utils/firebase";
import { notify } from "utils/common";
import useAnalyticsStore from "stores/useAnalyticsStore";
import EffectivenessCard from "components/Insights/EffectivenessCard/EffectivenessCard";
import MeVsMeetingCard from "components/Insights/MeVsMeetingCard/MeVsMeetingCard";
import HoursCard from "components/Insights/HoursCard/HoursCard";
import MeetingsTableCard from "components/Insights/MeetingsTableCard/MeetingsTableCard";
import ComingSoonCard from "components/Insights/ComingSoonCard/ComingSoonCard";
import FeedbackCardForOrg from "components/Insights/FeedbackCard/FeedbackCardForOrg";
import MeetingDistBarCard from "components/Insights/MeetingDistBarCard/MeetingDistBarCard";
import useOrgMeetingDetails from "hooks/insights/useOrgMeetingDetails";
import MeetingDistEffCard from "components/Insights/MeetingDistBarCard/MeetingDistEff";
import MeetingDistBySizeCard from "components/Insights/MeetingDistBarCard/MeetingDistBySizeCard";
import { useAutoAnimate } from "@formkit/auto-animate/react";
// import GoalSplitCard from "components/Insights/SplitCard/GoalSplitCard";
import useInsights from "hooks/insights/useInsights";
import EffectivenessLineCardForOrganization from "components/Insights/EffectivenessLineCard/EffectivenessLineCardForOrganization";
const Organization = () => {
  const { user } = useUser();
  const { duration: storedDuration } = useAnalyticsStore();
  const [data, setData] = useState(null);
  const [duration, setDuration] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [parent] = useAutoAnimate(/* optional config */);
  // mapping local duration to databse duration
  useEffect(() => {
    storedDuration === 7
      ? setDuration("7days")
      : storedDuration === 30
      ? setDuration("28days")
      : setDuration("91days");
  }, [storedDuration]);

  // fetching all information of the organization
  useEffect(() => {
    const getCostData = async () => {
      try {
        setLoading(true);
        const q = query(
          collection(FirestoreDB, `organizations`),
          where(documentId(), "==", user?.subscription?.organization_id)
        );
        const querySnapshot = await getDocs(q);
        let orgData = [];
        querySnapshot.forEach((doc) => {
          orgData.push(doc.data());
        });
        if (orgData.length === 0) {
          setError("No Data found");
        } else setData(orgData[0]);
      } catch (e) {
        setError("Error while fetching");
        notify("Unable to fetch cost data", "error");
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    getCostData();
  }, [user]);
  //get data of all meeting
  const {
    data: orgMeetingData,
    isLoading: isOrgMeetingDataLoading,
    isError: isOrgMeetingDataError,
  } = useOrgMeetingDetails({
    orgId: user?.subscription?.organization_id,
    duration: storedDuration,
  });

  const {
    orgMeVsMeeting,
    isOrgMeVsMeetingLoading,
    isOrgMeVsMeetingError,
    orgEffData,
    isOrgEffDataLoading,
    isOrgEffDataError,
  } = useInsights();

  return (
    <div className={css.container}>
      <div className={css.row} ref={parent}>
        <HoursCard
          asAn={"organization"}
          email={user.email}
          type="organizer"
          organizationId={user.subscription.organization_id}
          departmentName={user.subscription.department}
        />
        <CostCard
          isLoading={loading}
          isError={error}
          type="organizer"
          data={data?.meetingsCost?.organizer[`${duration}`]}
        />
        <CostCard
          isLoading={loading}
          isError={error}
          type="attendee"
          userEmail={user?.email}
          data={data?.meetingsCost?.attendee[`${duration}`]}
        />
      </div>
      <div className={css.row}>
        <EffectivenessCard
          type="organization"
          data={orgEffData}
          isLoading={isOrgEffDataLoading}
          isError={isOrgEffDataError}
        />
        <MeVsMeetingCard
          data={orgMeVsMeeting}
          isLoading={isOrgMeVsMeetingLoading}
          isError={isOrgMeVsMeetingError}
        />
        <FeedbackCardForOrg />
      </div>
      <div className={css.row}>
        {/* <GoalSplitCard
          data={orgMeetingData}
          isLoading={isOrgMeetingDataLoading}
          isError={isOrgMeetingDataError}
          type="by number of meetings"
        />
        <GoalSplitCard
          data={orgMeetingData}
          isLoading={isOrgMeetingDataLoading}
          isError={isOrgMeetingDataError}
          type="by meeting hours"
        /> */}
        {/* <GoalSplitCard
          data={orgMeetingData}
          isLoading={isOrgMeetingDataLoading}
          isError={isOrgMeetingDataError}
          type="by people hours"
        /> */}
        <EffectivenessLineCardForOrganization />
        <div></div>
      </div>
      {/* <div className={css.row}>
        <div></div>
        <div></div>
      </div> */}
      <div className={css.row}>
        <MeetingDistBarCard
          type={"organization"}
          data={orgMeetingData}
          isLoading={isOrgMeetingDataLoading}
          isError={isOrgMeetingDataError}
        />
        <MeetingDistEffCard
          type={"organization"}
          data={orgMeetingData}
          isLoading={isOrgMeetingDataLoading}
          isError={isOrgMeetingDataError}
        />
      </div>
      <div className={css.row}>
        <MeetingDistBySizeCard
          type={"organization"}
          data={orgMeetingData}
          isLoading={isOrgMeetingDataLoading}
          isError={isOrgMeetingDataError}
          over="meetings"
        />
        <MeetingDistBySizeCard
          type={"organization"}
          data={orgMeetingData}
          isLoading={isOrgMeetingDataLoading}
          isError={isOrgMeetingDataError}
          over="effectiveness"
          stackTotal={false}
        />
      </div>
      <div className={css.row}>
        <MeetingsTableCard
          data={orgMeetingData}
          isLoading={isOrgMeetingDataLoading}
          isError={isOrgMeetingDataError}
        />
      </div>
      <ComingSoonCard />
    </div>
  );
};

export default Organization;
