import { useEffect, useState } from "react";
import { Button, Chip, Loader, Modal, Rating, Textarea } from "@mantine/core";

import ModalWrapper from "components/ModalWrapper/ModalWrapper";

import useMeeting from "hooks/useMeeting";
import { feedbackTagOptions } from "utils/events";

import css from "./FeedbackModal.module.scss";
import TagModal from "./TagModal";
import MeetingTagsDropdownNew from "components/Workspace/Meeting/MeetingTagsDropdown/MeetingTagsDropdownNew";

const FeedbackModal = ({
  opened,
  setOpened,
  stars,
  eventId,
  feedback,
  source,
  meetingTitle,
  currentTag,
  tagOptions,
  handleOnTagClick,
}) => {
  const [loading, setLoading] = useState(false);
  const [feedbackTags, setFeedbackTags] = useState([]);
  const [customFeedback, setCustomFeedback] = useState("");
  const [tagModalOpened, setTagModalOpened] = useState(false);
  const { submitFeedback } = useMeeting();
  useEffect(() => {
    const { feedbackTags, customFeedback } = feedback || {
      feedbackTags: [],
      customFeedback: null,
    };
    setFeedbackTags(feedbackTags);
    setCustomFeedback(customFeedback);
  }, [feedback]);
  const handleClose = async () => {
    setOpened(false);
    // if there is not tag selected then ask for tag meeting
    !currentTag && setTagModalOpened(true);
  };

  const handleSubmit = async () => {
    setLoading(true);
    await submitFeedback(feedbackTags, customFeedback, eventId);
    setLoading(false);
    handleClose();
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => handleClose()}
        closeOnClickOutside={false}
        size={source === "extension" ? "95vw" : "44rem"}
      >
        <ModalWrapper>
          <div className={css.container}>
            <div className={css.rating}>
              <span>You have rated {stars} stars</span>

              <Rating value={stars} readOnly />
            </div>

            {/* tag selection */}
            <div className={css.tagWrapper}>
              <div className={css.tagContainer}>
                <span className={css.title}>Select tag :</span>
                <MeetingTagsDropdownNew
                  key={eventId}
                  tagOptions={tagOptions}
                  handleOnClick={handleOnTagClick}
                  currentTag={currentTag}
                />
              </div>

              {!currentTag && <span className={css.tagMessage}>Please select tag*</span>}
            </div>



            {/* feedback */}
            <div className={css.heading}>
              <span>Feedback</span>
              <span>
                Your feedback helps us to make meetings better for you
              </span>
            </div>
            <div className={css.tags}>
              <Chip.Group
                value={feedbackTags}
                onChange={setFeedbackTags}
                multiple
                style={source === "extension" ? { overflowX: "auto" } : {}}
              >
                {feedbackTagOptions.map((tag, i) => (
                  <Chip variant="filled" value={tag} key={i}>
                    {tag}
                  </Chip>
                ))}
              </Chip.Group>
            </div>
            <Textarea
              placeholder="Write here"
              label="Custom feedback (optional)"
              value={customFeedback || ""}
              onChange={(event) => setCustomFeedback(event.currentTarget.value)}
            />
            <div style={{ marginLeft: "auto" }}>
              <Button
                variant="primary"
                onClick={handleSubmit}
                disabled={
                  (feedbackTags.length === 0 && !customFeedback) || loading
                }
              >
                {loading ? <Loader color={"white"} size={20} /> : "Submit"}
              </Button>
            </div>
          </div>
        </ModalWrapper>
      </Modal>
      {/* <TagModal
        opened={tagModalOpened}
        setOpened={setTagModalOpened}
        meetingTitle={meetingTitle}
        tagOptions={tagOptions}
        handleOnTagClick={handleOnTagClick}
      /> */}
    </>
  );
};

export default FeedbackModal;
