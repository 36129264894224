import { MantineProvider } from "@mantine/core";
import AllRoutes from "routes";
import { NotificationsProvider } from "@mantine/notifications";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ModalsProvider } from "@mantine/modals";
import { theme } from "theme";
function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <MantineProvider
        theme={theme}
        withGlobalStyles
        withNormalizeCSS
      >
        <ModalsProvider>
          <NotificationsProvider>
            {/* <div id="gaintime-app"> */}
            <AllRoutes />
            {/* </div> */}
          </NotificationsProvider>
        </ModalsProvider>
      </MantineProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
export default App;
