import { Flex, Loader, Text, Title } from "@mantine/core";
import useOrgMeetingDetails from "hooks/insights/useOrgMeetingDetails";
import { sumBy } from "lodash";
import React, { useMemo } from "react";
import useAnalyticsStore from "stores/useAnalyticsStore";
import { useUser } from "stores/useUser";
import { getHighestFeedback } from "utils/insights";

const FeedbackCardForOrg = () => {
  const { duration } = useAnalyticsStore();
  const {
    user: {
      subscription: { organization_id: orgId },
    },
  } = useUser();
  const { data, isLoading, isError } = useOrgMeetingDetails({
    orgId,
    duration,
  });
  const mostProvidedFeedbacks = useMemo(() => {
    let feedbacks = [];
    data?.data?.map((meeting) => feedbacks.push(...meeting.feedbackTags));
    return getHighestFeedback(feedbacks);
  }, [data?.data]);

  if (isLoading) {
    return (
      <Wrapper>
        <div className="loader">
          <Loader />
          <span>Analysing feedbacks</span>
        </div>
      </Wrapper>
    );
  }
  if (isError || !data.status) {
    return (
      <Wrapper>
        <div className="loader">
          {data?.message || <span>Error while fetching feedbacks</span>}
        </div>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <Flex direction={"column"} gap={".8rem"}>
        {mostProvidedFeedbacks.slice(0, 3).map((feedback, i) => (
          <Text fz={".9rem"} key={i}>
            #{i + 1} - {Object.keys(feedback)[0]} ({Object.values(feedback)[0]})
          </Text>
        ))}
      </Flex>
      <Text>
        Total{" "}
        <b>
          {sumBy(
            mostProvidedFeedbacks,
            (feedback) => Object.values(feedback)[0]
          )}
        </b>{" "}
        feedbacks provided
      </Text>
    </Wrapper>
  );
};

const Wrapper = ({ children }) => {
  return (
    <div className="card">
      <Flex
        direction={"column"}
        justify={"space-between"}
        sx={{ width: "100%" }}
      >
        <Title order={5}>Most Provided Feedbacks</Title>
        {children}
      </Flex>
    </div>
  );
};

export default FeedbackCardForOrg;
