import React, { useMemo } from "react";
import MaterialReactTable from "material-react-table";
import { ThemeProvider, createTheme, darken } from "@mui/material";
import { Loader } from "@mantine/core";
import moment from "moment";
import teamStore from "stores/useTeamStore";
import { filterAttendees, getStrategicGoal } from "utils/insights";
// import { ExportToCsv } from "export-to-csv";

const MeetingsTableCard = ({ data, isLoading, isError }) => {
  const { strategicGoals } = teamStore.getState();

  const defaultMaterialTheme = createTheme();
  const columns = useMemo(
    () =>
      data
        ? [
            {
              accessorKey: "summary",
              header: "Title",
              size: 280,
            },
            {
              accessorFn: (row) =>
                parseFloat(row?.costInfo?.amount).toFixed(1) || "not available",
              header: "Cost",
              Cell: ({ cell }) => `$ ${cell.getValue()}`,
              size: 120,
            },
            {
              accessorKey: "averageRating",
              header: "Rating(avg)",
              size: 150,
            },
            {
              accessorFn: (row) =>
                parseFloat(
                  moment
                    .duration(
                      moment(row?.end?.dateTime).diff(
                        moment(row?.start?.dateTime)
                      )
                    )
                    .asHours()
                ).toFixed(1),
              header: "Duration (hrs)",
              size: 120,
            },
            {
              accessorKey: "organizer.email",
              header: "Organizer",
              size: 140,
            },
            {
              header: "Strategic goal",
              accessorFn: (row) =>
                getStrategicGoal(
                  strategicGoals,
                  row?.recurringEventId || row?.id
                ),
            },
            {
              accessorFn: (row) =>
                row?.attendees ? filterAttendees(row?.attendees)?.length : 0,
              header: "Attendees",
              size: 140,
            },
            {
              accessorFn: (row) =>
                moment(row?.start?.dateTime).format("DD/MM/YYYY"),
              header: "Date",
              sortingFn: "datetime",
              size: 140,
            },

            {
              accessorFn: (row) =>
                moment(row?.start?.dateTime).format("hh:mma"),
              header: "Started at",
              sortingFn: "datetime",
              size: 100,
            },
            {
              accessorFn: (row) => moment(row?.end?.dateTime).format("hh:mma"),
              header: "Ended at",
              sortingFn: "datetime",
              size: 100,
            },
          ]
        : [],
    [data]
  );

  // const csvOptions = {
  //   fieldSeparator: ",",
  //   quoteStrings: '"',
  //   decimalSeparator: ".",
  //   showLabels: true,
  //   useBom: true,
  //   useKeysAsHeaders: false,
  //   headers: columns.map((c) => c.header),
  // };
  // const csvExporter = new ExportToCsv(csvOptions);

  // const handleExportRows = (rows) => {
  //   csvExporter.generateCsv(rows.map((row) => ({summary: row.original.summary, cost: row.orignal?.cost, averageRating: 0, duration: -0, organizer: null, attendees: 0, date: null, startedAt : 0, endedAt: 0})));
  // };

  // const handleExportData = () => {
  //   csvExporter.generateCsv(data);
  // };

  if (isLoading) {
    return (
      <div className="card">
        <div className="loader">
          <Loader />
          <span>Fetching meetings</span>
        </div>
      </div>
    );
  }

  if (isError || !data?.status) {
    return (
      <div className="card">
        <div className="loader">
          <span>Error while fetching meetings</span>
        </div>
      </div>
    );
  }

  if (!isLoading)
    return (
      <div className="card" style={{ flex: 2, overflow: "auto" }}>
        <ThemeProvider theme={defaultMaterialTheme}>
          <MaterialReactTable
            style={{ height: "10rem" }}
            columns={columns}
            // enableRowSelection
            // renderTopToolbarCustomActions={({ table }) => (
            //   <Box
            //     sx={{
            //       display: "flex",
            //       gap: "1rem",
            //       p: "0.5rem",
            //       flexWrap: "wrap",
            //     }}
            //   >
            //     <Button
            //       color="primary"
            //       //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
            //       onClick={handleExportData}
            //       // startIcon={<FileDownloadIcon />}
            //       variant="contained"
            //     >
            //       Export All Data
            //     </Button>
            //     <Button
            //       disabled={table.getPrePaginationRowModel().rows.length === 0}
            //       //export all rows, including from the next page, (still respects filtering and sorting)
            //       onClick={() =>
            //         handleExportRows(table.getPrePaginationRowModel().rows)
            //       }
            //       // startIcon={<FileDownloadIcon />}
            //       variant="contained"
            //     >
            //       Export All Rows
            //     </Button>
            //     <Button
            //       disabled={table.getRowModel().rows.length === 0}
            //       //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
            //       onClick={() => handleExportRows(table.getRowModel().rows)}
            //       // startIcon={<FileDownloadIcon />}
            //       variant="contained"
            //     >
            //       Export Page Rows
            //     </Button>
            //     <Button
            //       disabled={
            //         !table.getIsSomeRowsSelected() &&
            //         !table.getIsAllRowsSelected()
            //       }
            //       //only export selected rows
            //       onClick={() =>
            //         handleExportRows(table.getSelectedRowModel().rows)
            //       }
            //       // startIcon={<FileDownloadIcon />}
            //       variant="contained"
            //     >
            //       Export Selected Rows
            //     </Button>
            //   </Box>
            // )}
            data={data ? data.data : []}
            enableGrouping
            enableStickyHeader
            enableColumnOrdering
            enableFullScreenToggle={true}
            // onIsFullScreenChange={(message)=>console.log(message)}
            enableDensityToggle={false}
            initialState={{
              density: "compact",
              expanded: false, //expand all groups by default
              pagination: { pageIndex: 0, pageSize: 10 },
              // sorting: [{ id: "state", desc: false }] //sort by state by default
            }}
            muiTablePaperProps={{
              elevation: 0,
              sx: {
                borderRadius: "0",
                border: "none",
                width: "100%",
              },
            }}
            muiTableContainerProps={{
              sx: {
                maxHeight: "25rem",
                overflow: "auto",
                width: "100%",
              },
            }}
            muiTableProps={{
              sx: {
                tableLayout: "fixed",
              },
            }}
            muiTableBodyProps={{
              sx: (theme) => ({
                "& tr:nth-of-type(odd)": {
                  backgroundColor: darken(
                    theme.palette.background.default,
                    0.02
                  ),
                },
              }),
            }}
          />
        </ThemeProvider>
      </div>
    );
};

export default MeetingsTableCard;
