import { Flex, Loader, Title } from "@mantine/core";
import React from "react";
import ReactECharts from "echarts-for-react";

const LineCard = ({
  isLoading,
  isError,
  xAxisData,
  series,
  title = "Line Card",
  yAxisMax,
  rotate
}) => {
  const option = React.useMemo(() => {
   
    return {
      xAxis: {
        // to get the first three letters of day name
        data: xAxisData,
        axisLabel: {
          interval: 0,
          rotate: rotate
        },
      },
      yAxis: {
        max: yAxisMax,
      },
      
      series: series,
      grid: {
        top: "15%",
        right: "10%",
        bottom: "25%",
        left: "5%",
      },
      tooltip: {
        trigger: "axis",
      },
    };
  }, [xAxisData, series, rotate, yAxisMax]);
  if (isError) {
    return (
      <Wrapper title={title}>
        <div className="loader">
          <span>Error occured</span>
        </div>
      </Wrapper>
    );
  }
  if (isLoading) {
    return (
      <Wrapper title={title}>
        <div className="loader">
          <Loader />
          <span>Loading...</span>
        </div>
      </Wrapper>
    );
  }
  return (
    <Wrapper title={title}>
      <ReactECharts
        option={option}
        style={{ height: "100%", width: "100%" }}
        opts={{ renderer: "svg" }}
      />
    </Wrapper>
  );
};
const Wrapper = ({ children, title }) => {
  return (
    <div className="card">
      <Flex direction={"column"} sx={{ width: "100%", height: "100%" }}>
        <Title order={5}>{title}</Title>
        {children}
      </Flex>
    </div>
  );
};
export default LineCard;
