import { Flex, Title } from "@mantine/core";
import React from "react";

const ComingSoonCard = () => {
  return (
    <Flex
      w={"100%"}
      align={"center"}
      justify={"center"}
      h={"15rem"}
      mb={"1rem"}
      sx={{
        background:
          "linear-gradient(266deg, #2BDFF8 10%, rgb(75 17 245 / 48%) 98.58%)",
        borderRadius: "10px",
      }}
    >
      <Title order={2} color="white">
        More Insights Coming soon ...
      </Title>
    </Flex>
  );
};

export default ComingSoonCard;
