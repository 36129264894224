import { Navigate, useLocation } from "react-router-dom";

import Layout from "layout/Layout";

import { useUser } from "stores/useUser";
import { isSourceExtension } from "utils/common";

export function RequireAuth() {
  const location = useLocation();
  const { user } = useUser();

  const auth = user?.credentials?.accessToken ? true : false;

  const source = isSourceExtension(location.pathname) ? "extension" : null;

  if (!auth) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location, source }} />;
  }

  if (!user.subscription?.plan) {
    console.log(
      "has org and org is not onboarded or has not subscribed or he does not have org"
    );
    return <Navigate to="/subscription" />;
  }

  return (
    // defining layout, all the routes are going to be nested inside this layout, also EVENTS data is fetched in layout
    <Layout source={source} />
  );
}
