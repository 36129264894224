import {
  Box,
  Card,
  Container,
  Flex,
  Grid,
  Group,
  Image,
  Paper,
  Progress,
  RingProgress,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import React from "react";
import inboxBg from "assets/inbox-bg.png";
import { useUser } from "stores/useUser";
import useAnalyticsStore from "stores/useAnalyticsStore";
import useActionsSummary from "hooks/insights/useActionsSummary";
import ActionsTable from "components/Insights/ActionsTable/ActionsTable";
import ActionsSummarySkelton from "components/Insights/ActionsSummarySkelton";
import ComparisonCard from "components/Insights/ComparisonCard/ComparisonCard";
const actionsCard = [
  {
    id: "total",
    title: "Total Actions",
    bg: "#D0F0E4",
    color: "#004B50",
  },
  {
    id: "completed",
    title: "Completed Actions",
    bg: "#D4F5F9;",
    color: "#003768",
  },
  {
    id: "pending",
    title: "Pending Actions",
    bg: "#FEF1D3",
    color: "#844F11",
  },
  {
    id: "overDue",
    title: "Over due date",
    bg: "#FFE0D8",
    color: "#7A0916",
  },
];

const sourceOfActions = [
  {
    type: "by_you",
    name: "Created by you",
    color: "#47d6ab",
  },
  {
    type: "by_others",
    name: "Assigned by others",
    color: "yellow.3",
  },
];

const ActionsInsights = () => {
  const { user } = useUser();
  const { duration } = useAnalyticsStore();
  const { data , isLoading, isError } = useActionsSummary({
    userId: user?.uid,
    duration,
  });

  return (
    <>
      <Container fluid>
        {isLoading || isError ? (
          <ActionsSummarySkelton isError={isError} />
        ) : (
          <Grid>
            {/* //* top stats in 4 cards */}
            {actionsCard.map((card, i) => (
              <Grid.Col key={i} span={3}>
                <Paper bg={card.bg} radius={"lg"}>
                  <Flex
                    align={"center"}
                    justify={"center"}
                    gap={10}
                    direction={"column"}
                    py={50}
                  >
                    <Text c={card.color} size={"1.8rem"} fw={500}>
                      {data?.inTheDuration?.[card.id]}
                    </Text>
                    <Text c={card.color} size={"md"}>
                      {card.title}
                    </Text>
                  </Flex>
                </Paper>
              </Grid.Col>
            ))}

            {/* //* source of action items card */}
            <Grid.Col span={6}>
              <Card shadow="sm" p="lg" radius="md">
                <Title order={5}>Source of action items</Title>

                <Progress
                  mt={20}
                  radius={"md"}
                  size={40}
                  sections={[
                    {
                      value: data?.inTheDuration?.source?.by_you?.percentage,
                      color: "#47d6ab",
                      label: (
                        <Text size={"md"}>
                          {data?.inTheDuration?.source?.by_you?.percentage || 0}%
                        </Text>
                      ),
                    },
                    {
                      value: data?.inTheDuration?.source?.by_others?.percentage,
                      color: "yellow.3",
                      label: (
                        <Text size={"md"}>
                          {data?.inTheDuration?.source?.by_others?.percentage || 0}%
                        </Text>
                      ),
                    },
                  ]}
                />
                {/* bottom summary */}
                <Grid mt={20}>
                  {sourceOfActions.map((section, i) => (
                    <Grid.Col span={6} key={i}>
                      <Stack spacing={10}>
                        <Text c="black.4" size="sm" fw={"bold"}>
                          {section.name}
                        </Text>
                        <Group position="apart">
                          <Group align="flex-end">
                            <Text size={"xl"} fw={"bold"}>
                              {data?.inTheDuration?.source?.[section.type]?.total}
                            </Text>
                            <Text fw={500} c="black.3">
                              action items
                            </Text>
                          </Group>
                          <Text fw={"bold"} c={section.color}>
                            {data?.inTheDuration?.source?.[section.type]?.percentage || 0}%
                          </Text>
                        </Group>

                        <Box
                          fluid
                          h={10}
                          mt={10}
                          sx={{ borderRadius: 99 }}
                          bg={section.color}
                        />
                      </Stack>
                    </Grid.Col>
                  ))}
                </Grid>
              </Card>
            </Grid.Col>

            {/* //* Completed assigned tasks card */}
            <Grid.Col span={6}>
              <Paper
                bg={"#D4F5F9"}
                p={"lg"}
                radius={"md"}
                h={"100%"}
                sx={{ position: "relative" }}
              >
                <Image
                  alt="inbox"
                  width={150}
                  src={inboxBg}
                  sx={{ position: "absolute", right: 0, top: "20%" }}
                />
                <Title order={5}>Completed assigned tasks</Title>
                <Group spacing={6} mt={20} noWrap>
                  <RingProgress
                    size={150}
                    thickness={16}
                    roundCaps
                    label={
                      <Title order={5} align="center" px="xs">
                        {data?.inTheDuration?.source?.by_others?.completion_percentage}%
                      </Title>
                    }
                    sections={[
                      {
                        value: data?.inTheDuration?.source?.by_others?.completion_percentage,
                        color: "#02B9DA",
                      },
                    ]}
                  />
                  <Stack spacing={8}>
                    <Group align="flex-end" spacing={6}>
                      <Title order={1} inline>
                        {data?.inTheDuration?.source?.by_others?.completed}
                      </Title>
                      <Text fw={500} c={"#0037688A"}>
                        out of {data?.inTheDuration?.source?.by_others?.total}
                      </Text>
                    </Group>
                    <Text fw={500} c={"#0037688A"} size={"sm"}>
                      Action items that are assigned by others in your team.
                      <br /> Only accepted action items via inbox are considered
                      here.
                    </Text>
                  </Stack>
                </Group>
              </Paper>
            </Grid.Col>

            <Grid.Col span={12}>
             <ComparisonCard data={data}/>
            </Grid.Col>

            {/* //* prev action items table*/}
            {data?.inTheDuration && (
              <Grid.Col span={12} mt={12}>
                <Stack>
                  <Title order={5}>Previous action items within the duration</Title>
                  <ActionsTable data={data?.inTheDuration?.actions} />
                </Stack>
              </Grid.Col>
            )}


            {/* //* upcoming action items */}
          </Grid>
        )}
      </Container>
    </>
  );
};

export default ActionsInsights;
