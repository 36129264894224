import { MeTimeMeetingDetails } from "api";
import { useQuery } from "react-query";

const useMeTimeMeetingDetails = ({ userEmail, organizationId, duration, enabled = true }) => {
  const { data, isLoading, isError } = useQuery({
    queryFn: () => MeTimeMeetingDetails(userEmail, organizationId, duration),
    queryKey: ["meTimeMeetingDetails", userEmail, organizationId, duration],
    staleTime: 30 * 60000, //half hour (30 minutes)
    enabled
  });
  return { data, isLoading, isError };
};

export default useMeTimeMeetingDetails;
