import {
  doc,
  collection,
  query,
  orderBy,
  onSnapshot,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useUser } from "stores/useUser";
import { notify } from "utils/common";
import { FirestoreDB } from "utils/firebase";

const useSharedActions = () => {
  const [actionSharedWith, setActionSharedWith] = useState([]);
  const { user } = useUser();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    let unsubscribe;
    const getActions = async () => {
      try {
        setLoading(true);
        const q = query(
          collection(FirestoreDB, `users/${user.uid}/ActionSharedWith`),
          orderBy("created_on", "desc")
        );

        unsubscribe = onSnapshot(q, async (querySnapshot) => {
          const res = [];
          querySnapshot.forEach((doc) => {
            res.push({ id: doc.id, ...doc.data() });
          });
          // by default setting by created time
          setActionSharedWith(res);
          setLoading(false);
        });
      } catch (e) {
        console.log(e);
        notify("Unable to fetch Action items", "error");
        setError(true);
      }
    };
    getActions();

    return () => {
      console.log("unsubscribing actions");
      unsubscribe();
    };
  }, [user, setLoading, setError]);

  const addAccess = async (person) => {
    console.log(person);
    const timeStamp = new Date()
    try {
      // updating current user's ActionSharedWith
      const userRef = doc(FirestoreDB, `users/${user?.uid}`);
      const collectionRef = collection(userRef, "ActionSharedWith");
      await setDoc(doc(collectionRef, person?.value), {
        id: person?.value,
        name: person?.label,
        uid: person?.uid,
        created_on: timeStamp,
      });

      // updating receivers's ActionAccess
      const receiverRef = doc(FirestoreDB, `users/${person?.uid}`);
      const recieverCollectionRef = collection(receiverRef, "ActionAccess");
      await setDoc(doc(recieverCollectionRef, user?.email), {
        id: user?.email,
        name: user?.displayName,
        uid: user?.uid,
        created_on: timeStamp,
      });
      notify(`Share with ${person?.label}`, "success");
    } catch (e) {
      console.log(e);
    }
  };

  const removeAccess = async (person) => {
    try {
      await deleteDoc(
        doc(FirestoreDB, `users/${user.uid}/ActionSharedWith/${person?.id}`)
      );
      await deleteDoc(
        doc(FirestoreDB, `users/${person.uid}/ActionAccess/${user?.email}`)
      )
    } catch (e) {
      console.log(e);
    }
  };
  return {
    addAccess,
    actionSharedWith,
    loading,
    error,
    removeAccess,
  };
};

export default useSharedActions;
