import { Modal } from "@mantine/core";
import React, { useMemo } from "react";
import MaterialReactTable from "material-react-table";
import { ThemeProvider, createTheme, darken } from "@mui/material";
import moment from "moment";
import Tooltip from "components/Tooltip/Tooltip";

const CustomFeebackModal = ({ opened, setOpened, data }) => {
  const defaultMaterialTheme = createTheme();

  const columns = useMemo(
    () =>
      data
        ? [
            {
              accessorFn: (fb) => fb?.feedback,
              header: "Feedback",
              size: 350,
              Cell: ({ cell }) => (
                <Tooltip width="auto" label={cell.getValue()}>
                  <span>{cell.getValue()}</span>
                </Tooltip>
              ),
            },
            {
              header: "Title",
              accessorFn: (fb) => fb?.title,
              Cell: ({ cell }) => (
                <Tooltip width="auto" label={cell.getValue()}>
                  <span>{cell.getValue()}</span>
                </Tooltip>
              ),
            },
            {
              header: "Organizer",
              accessorFn: (fb) => fb?.organizer,
              Cell: ({ cell }) => (
                <Tooltip width="auto" label={cell.getValue()}>
                  <span>{cell.getValue()}</span>
                </Tooltip>
              ),
            },
            {
              header: "Attendees",
              accessorFn: (fb) => fb?.attendees?.length,
              size: 100
            },
            {
              header: "Started at",
              accessorFn: (fb) => moment(fb?.startTime).format("hh:mm:A"),
            },
            {
              header: "Ended at",
              accessorFn: (fb) => moment(fb?.endTime).format("hh:mm:A"),
            },
            {
              header: "Date",
              accessorFn: (fb) => moment(fb?.date).format("ddd-MMM-YY"),
            },
          ]
        : [],
    [data]
  );
  return (
    <Modal
      opened={opened}
      onClose={() => setOpened(false)}
      closeOnClickOutside
      overlayOpacity={0.55}
      overlayBlur={3}
      size={"75rem"}
      centered
      title={"Custom Feedbacks"}
      overflow="inside"
    >
      <ThemeProvider theme={defaultMaterialTheme}>
        <MaterialReactTable
          style={{ height: "10rem" }}
          columns={columns}
          data={data}
          enableGrouping
          enableStickyHeader
          enableColumnOrdering
          enableFullScreenToggle={false}
          // onIsFullScreenChange={(message)=>console.log(message)}
          enableDensityToggle={false}
          initialState={{
            density: "compact",
            expanded: false, //expand all groups by default
            pagination: { pageIndex: 0, pageSize: 10 },
            // sorting: [{ id: "state", desc: false }] //sort by state by default
          }}
          muiTablePaperProps={{
            elevation: 0,
            sx: {
              borderRadius: "0",
              border: "none",
              width: "100%",
            },
          }}
          muiTableContainerProps={{
            sx: {
              maxHeight: "25rem",
              overflow: "auto",
              width: "100%",
            },
          }}
          muiTableProps={{
            sx: {
              tableLayout: "fixed",
            },
          }}
          muiTableBodyProps={{
            sx: (theme) => ({
              "& tr:nth-of-type(odd)": {
                backgroundColor: darken(theme.palette.background.default, 0.02),
              },
            }),
          }}
        />
      </ThemeProvider>
    </Modal>
  );
};

export default CustomFeebackModal;
