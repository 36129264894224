import moment from "moment";
import { getUserEventResponse } from "./events";

export const shouldEventBeDisplayed = (userEventResponse) =>
  userEventResponse !== "declined";

export const cleanDescription = (description) => {
  if (description) {
    const splits = description.split("──────────");
    if (splits.length >= 3) {
      // run the for loop and merge all the even postions
      description = splits
        .filter((_, i) => i % 2 === 0)
        .reduce((curr, acc) => acc + curr, "");
      return description;
    }
    return description;
    // return description.replace(/──────────.*?──────────/g, '');
  }
  return description;
};

export const parseCalendarEvents = (user, events) => {
  if (!events?.length) {
    return [];
  }

  const parsedData = events
    .map((event) => {
      if (event.kind !== "calendar#event") {
        return null;
      }
      const response = getUserEventResponse(event, user);
      if (response === "declined") {
        return null;
      }
      const id = event.id;
      const title = event.summary || null;
      const description = event.description || null;
      const start = event.start.dateTime;
      const end = event.end.dateTime;
      const calendarEvent = event;
      const hasEventEnded = moment(event.end.dateTime).isBefore(moment());
      const isOngoing = moment().isBetween(
        moment(event.start.dateTime),
        moment(event.end.dateTime)
      );
      const recurringEventId = event.recurringEventId
      if (title) {
        const userEventResponse = getUserEventResponse(calendarEvent, user);
        const displayEvent = shouldEventBeDisplayed(userEventResponse);
        const backgroundColor = "rgb(54, 153, 236)";

        return {
          id,
          title,
          description: cleanDescription(description),
          start,
          end,
          calendarEvent,
          userEventResponse,
          display: displayEvent ? "auto" : "none",
          backgroundColor,
          allDay: false,
          hasEventEnded,
          isOngoing,
          recurringEventId
        };
      } else {
        return null;
      }
    })
    .filter((event) => event !== null);

  return parsedData;
};

export const getEventInnerBlockClass = (diff) => {
  if (diff) {
    if (diff <= 15) {
      return "calendar-event very-small";
    }
    if (diff > 15 && diff <= 20) {
      return "calendar-event small";
    }
    if (diff > 20 && diff <= 25) {
      return "calendar-event mid";
    }
    if (diff > 25 && diff <= 30) {
      return "calendar-event mid-plus";
    }
    if (diff > 30 && diff < 55) {
      return "calendar-event big";
    }
    if (diff >= 55 && diff <= 60) {
      return "calendar-event big-plus";
    }
    if (diff > 60) {
      return "calendar-event very-big wrap-text";
    }
  }
  return "calendar-event";
};

export const getEventOuterBlockClass = (info) => {
  // console.log(info);

  let eventClass = "event-block";
  const userEventResponse = info.event.extendedProps.userEventResponse;

  if (userEventResponse) {
    return eventClass + " " + userEventResponse;
  }
  console.log(eventClass)
  return eventClass;
};
