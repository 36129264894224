import { create } from "zustand";
const useCalendarStore = create((set) => ({
  loading: false,
  metaDataLoading: false,
  error: false,
  metaDataError: false,
  activeDateRange: null,
  events: [],
  metaData: [],
  setSelectedDate: (data) =>
    set(() => ({
      selectedDate: data,
    })),
  setEvents: (data) => set(() => ({ events: data })),
  setMetaData: (data) => set(() => ({ metaData: data })),
  setActiveDateRange: (data) => set(() => ({ activeDateRange: data })),
  setLoading: (status) => set(() => ({ loading: status })),
  setMetaDataLoading: (status) => set(() => ({ metaDataLoading: status })),
  setError: (status) => set(() => ({ error: status })),
  setMetaDataError: (status) => set(() => ({ metaDataError: status })),
  updateMetaData: (type, eventId, data) => set((state) => {

    const { metaData } = state;
    let updatedMetaData = [...metaData]
    const index = updatedMetaData.findIndex((item) => item.id === eventId);
    if (type === "rating") {
      index !== -1 ?
        updatedMetaData[index] = { ...updatedMetaData[index], rating: data }
        :
        updatedMetaData = [...updatedMetaData, { id: eventId, rating: data }];
    }
    else if (type === "feedback") {
      index !== -1 ?
        updatedMetaData[index] = { ...updatedMetaData[index], feedback: data } :
        updatedMetaData = [...updatedMetaData, { id: eventId, feedback: data }];
    }
    else if (type === "tag") {
      index !== -1 ?
        updatedMetaData[index] = { ...updatedMetaData[index], tag: data } :
        updatedMetaData = [...updatedMetaData, { id: eventId, tag: data }];
    }
    else if (type === 'hasAttended') {
      index !== -1 ?
        updatedMetaData[index] = { ...updatedMetaData[index], hasAttended: data } :
        updatedMetaData = [...updatedMetaData, { id: eventId, hasAttended: data }];
    }

    return { metaData: updatedMetaData }

  })
}));


export default useCalendarStore;
