import { collection, onSnapshot, query } from "firebase/firestore";
import { orderBy } from "lodash";
import { useEffect } from "react";
import useActionsStore from "stores/useActionsStore";
import { useUser } from "stores/useUser";
import {
  checkForInboxNotifications,
  checkForOutboxNotifications,
  checkForShareActionsNotification,
  notify,
} from "utils/common";
import { FirestoreDB } from "utils/firebase";

const useFirebaseActions = () => {
  const {
    setActionItems,
    setLoading,
    setError,
    outboxItems,
    setOutboxItems,
    setOutboxLoading,
    setOutboxError,
    setSharedAccessList,
    setSharedAccessLoading,
    setSharedAccessError
  } = useActionsStore();
  const { user } = useUser();

  // for getting actions
  useEffect(() => {
    let unsubscribe;
    const getActions = async () => {
      try {
        setLoading(true);
        const q = query(
          collection(FirestoreDB, `users/${user.uid}/ActionItems`),
          orderBy("created_on", "desc")
        );

        unsubscribe = onSnapshot(q, async (querySnapshot) => {
          const res = [];
          querySnapshot.forEach((doc) => {
            res.push({ id: doc.id, ...doc.data() });
          });
          // by default setting by created time
          setActionItems(
            res.slice().sort((a, b) => b.created_on - a.created_on)
          );

          querySnapshot
            .docChanges()
            .forEach((change) =>
              checkForInboxNotifications(change, user?.lastTimeAccessed)
            );
        });
      } catch (e) {
        console.log(e);
        notify("Unable to fetch Action items", "error");
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    getActions();

    return () => {
      console.log("unsubscribing actions");
      unsubscribe();
    };
  }, [user, setActionItems, setLoading, setError]);

  // for getting outbox items
  useEffect(() => {
    let unSubscribe;
    const getOuboxItems = async () => {
      try {
        setOutboxLoading(true);
        const q = query(
          collection(FirestoreDB, `users/${user.uid}/Outbox`),
          orderBy("created_on", "desc")
        );
        console.log("SUBSCRIBE OUTBOX items");

        unSubscribe = onSnapshot(q, async (querySnapshot) => {
          const res = [];
          querySnapshot.forEach((doc) => {
            res.push({ id: doc.id, ...doc.data() });
          });
          querySnapshot.docChanges().forEach((change) =>
          checkForOutboxNotifications(
            change,
            user?.lastTimeAccessed,
            // sending the prev outbox items to make compare
              outboxItems
            )
          );
          setOutboxItems(
            res.slice().sort((a, b) => b.created_on - a.created_on)
          );
          setOutboxLoading(false);
        });
      } catch (error) {
        console.log(error);
        notify("Unable to fetch Action items", "error");
        setOutboxError(true);
      }
    };

    getOuboxItems();
    return () => {
      console.log("UNSUBSCRIBE OUTBOX items");
      unSubscribe();
    };
  }, [user, setOutboxError, setOutboxItems, setOutboxLoading]);

  // for getting SharedAccess persons
  useEffect(() => {
    let unSubscribe;
    const getSharedAccessList = async () => {
      try {
        setSharedAccessLoading(true);
        const q = query(
          collection(FirestoreDB, `users/${user.uid}/ActionAccess`),
          orderBy("created_on", "desc")
        );
        console.log("SUBSCRIBE Action access items");

        unSubscribe = onSnapshot(q, async (querySnapshot) => {
          const res = [];
          querySnapshot.forEach((doc) => {
            res.push({ id: doc.id, ...doc.data() });
          });
          querySnapshot.docChanges().forEach((change) =>
          checkForShareActionsNotification(
            change,
            user?.lastTimeAccessed,
            )
          );
          setSharedAccessList(
            res.slice().sort((a, b) => b.created_on - a.created_on)
          );
          setSharedAccessLoading(false);
        });
      } catch (error) {
        console.log(error);
        notify("Unable to fetch Shared Action list items", "error");
        setSharedAccessError(true);
      }
    };

    getSharedAccessList();
    return () => {
      console.log("UNSUBSCRIBE Shared action access list items");
      unSubscribe();
    };
  }, [user, setSharedAccessError, setSharedAccessLoading, setSharedAccessList]);
  return null;
};

export default useFirebaseActions;
