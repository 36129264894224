import { Flex, Loader, Text, Title } from "@mantine/core";
import React, { useMemo } from "react";
import ReactECharts from "echarts-for-react";
import {
  getOrgMeetingDist,
  getPointsForEffDis,
  getXAxisForEffDis,
} from "utils/insights";

const MeetingDistEffCard = ({ type, data, isLoading, isError, email }) => {
  // if type is organization then manipulating org details
  const formattedData = useMemo(
    () => type === "organization" && getOrgMeetingDist(email, data?.data),
    [data, email, type]
  );

  const option = useMemo(() => {
    if (data?.status) {
      const series = () => {
        // bars according to rating scores on y axis
        return [
          {
            name: "External Effectiveness",
            type: "bar",
            data: getPointsForEffDis(
              type !== "organization"
                ? data?.data?.byLength
                : formattedData?.byLength,
              "external"
            ),
            itemStyle: {
              color: "#fab005",
            },
          },
          {
            name: "Internal Effectiveness",
            type: "bar",
            data: getPointsForEffDis(
              type !== "organization"
                ? data?.data?.byLength
                : formattedData?.byLength,
              "internal"
            ),
            itemStyle: {
              color: "#39a8f0",
            },
            show: false,
          },
        ];
      };

      return {
        xAxis: {
          data: getXAxisForEffDis( type !== "organization"
          ? data?.data?.byLength
          : formattedData?.byLength,),
          name: "Minutes",
          nameLocation: "middle",
          nameTextStyle: {
            padding: 15,
          },
          axisLabel: {
            interval: 0,
          },
        },
        yAxis: {
          type: "value",
          min: 0,
          max: 5,
          name: "effectiveness",
          nameLocation: "middle",
          nameTextStyle: {
            padding: 15,
          },
          axisLabel: {
            interval: 0,
          },
        },
        series: series(),
        grid: {
          top: "10%",
          right: "5%",
          bottom: "20%",
          left: "9%",
        },
        tooltip: {
          trigger: "axis",
        },
      };
    }
  }, [data?.data, data?.status, type, formattedData]);

  if (isLoading) {
    return (
      <Wrapper>
        <div className="loader">
          <Loader />
          <span>Fetching meeting distribution</span>
        </div>
      </Wrapper>
    );
  }
  if (isError || !data.status) {
    return (
      <Wrapper>
        <div className="loader">
          {data?.message || <span>Error while fetching time distribution</span>}
        </div>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <ReactECharts
        option={option}
        style={{ height: "100%", width: "100%", alignSelf: "center" }}
        opts={{ renderer: "svg" }}
      />
    </Wrapper>
  );
};

export default MeetingDistEffCard;

const Wrapper = ({ children }) => {
  return (
    <div className="card" style={{ height: "21rem" }}>
      <Flex direction={"column"} sx={{ width: "100%" }}>
        <Flex direction={"column"}>
          <Title order={5}>Distribution of effectiveness</Title>
          <Text c="dimmed" fz={"15px"}>
            over the length
          </Text>
        </Flex>
        {children}
      </Flex>
    </div>
  );
};
