import { create } from "zustand";

export const useUser = create((set) => ({
  user: null,
  tags: [],
  slackChannels: [],
  slackUsers: [],

  setUser: (user) =>
    set(() => ({
      user,
    })),

  setTags: (tags) =>
    set(() => ({
      tags,
    })),

  setSlackChannels: (slackChannels) =>
    set(() => ({
      slackChannels,
    })),

  setSlackUsers: (slackUsers) =>
    set(() => ({
      slackUsers,
    })),
}));
