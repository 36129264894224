import { Flex, Loader, Text, Title } from "@mantine/core";
import React from "react";
import ReactECharts from "echarts-for-react";
import EmptyCard from "../EmptyCard/EmptyCard";

const MeVsMeetingCard = ({
  type = "organization",
  label,
  data,
  isLoading,
  isError,
}) => {
  const option = {
    tooltip: {
      trigger: "item",
    },

    series: [
      {
        name: "In split",
        type: "pie",
        radius: "70%",
        data: [
          { value: data?.meHrs, name: "Me Time" },
          { value: data?.meetingHrs, name: "Meeting Time" },
        ],
        color: ["#fab005", "#39a8f0"],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
  if (isLoading) {
    return (
      <MeVsMeetingCardWrapper data={data} label={label}>
        <div className="loader">
          <Loader />
          <span>Fetching time split</span>
        </div>
      </MeVsMeetingCardWrapper>
    );
  }
  if (isError) {
    return (
      <MeVsMeetingCardWrapper data={data} label={label}>
        <div className="loader">
          <span>Error while fetching time split</span>
        </div>
      </MeVsMeetingCardWrapper>
    );
  }

  if (!isLoading && !isError && !data?.meHrs && !data?.meetingHrs) {
    return (
      <MeVsMeetingCardWrapper data={data} label={label}>
        <EmptyCard />
      </MeVsMeetingCardWrapper>
    );
  }

  return (
    <MeVsMeetingCardWrapper data={data} label={label}>
      <ReactECharts
        option={option}
        style={{ height: "100%", width: "100%", alignSelf: "center" }}
        opts={{ renderer: "svg" }}
      />
    </MeVsMeetingCardWrapper>
  );
};

export default MeVsMeetingCard;

const MeVsMeetingCardWrapper = ({ children, data, label = "Time split" }) => {
  return (
    <div className="card">
      <Flex direction={"column"} sx={{ width: "100%" }}>
        <Title order={5}>{label}</Title>

        {/* checking if data available */}
        {children}

        {/* only for my organization, this data is available */}
        {typeof data?.externalMeetingHours === "number" &&
          typeof data?.internalMeetingHours === "number" && (
            <Flex direction={"column"} fz={".9rem"}>
              <Text>
                Internal meeting hrs:{" "}
                <b> {(data?.internalMeetingHours).toFixed(1)} hrs</b>
              </Text>
              <Text>
                External meeting hrs:{" "}
                <b>{(data?.externalMeetingHours).toFixed(1)} hrs</b>
              </Text>
            </Flex>
          )}
      </Flex>
    </div>
  );
};
