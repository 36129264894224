import css from "./InviteInput.module.scss";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";

const InviteInput = ({ emails, changeHandler }) => {

  return (
    <div className={css.container}>
      <span>Add People using their Emails</span>
      <TagsInput
        id="emails-input"
        value={emails}
        onChange={changeHandler}
        validationRegex={/^[^\s@]+@[^\s@]+\.[^\s@]+$/}
        onlyUnique={true}
        addOnBlur={true}
        addOnPaste={true}
        inputProps={{
          type: "email",
          placeholder: "Enter address",
        }}
      />
    </div>
  );
};

export default InviteInput;
