import { useAutoAnimate } from "@formkit/auto-animate/react";
import { Flex, Loader, Text, Title } from "@mantine/core";
import { sumBy } from "lodash";
import React from "react";

const FeedbackCard = ({ data, isLoading, isError }) => {
  const [parent] = useAutoAnimate(/* optional config */);
  if (isLoading) {
    return (
      <Wrapper>
        <div className="loader">
          <Loader />
          <span>Analysing feedbacks</span>
        </div>
      </Wrapper>
    );
  }
  if (isError) {
    return (
      <Wrapper>
        <div className="loader">
          {data?.message || <span>Error while fetching feedbacks</span>}
        </div>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <Flex direction={"column"} gap={".8rem"} ref={parent}>
        {Object.keys(data)
          .slice(0, 4)
          .map((feedback, i) => (
            <Text fz={".9rem"} key={i}>
              #{i + 1} - {feedback} ({data?.[feedback]?.count})
            </Text>
          ))}
      </Flex>
      <Text>
        Total <b>{sumBy(Object.values(data).map((value)=>value?.count))}</b> feedbacks provided
      </Text>
    </Wrapper>
  );
};

const Wrapper = ({ children, type, title }) => {
  return (
    <div className="card" style={{ height: "16rem" }}>
      <Flex
        direction={"column"}
        sx={{ width: "100%" }}
        justify={"space-between"}
      >
        <Flex direction={"column"}>
          <Title order={5}>Structured Feedbacks</Title>
        </Flex>
        {children}
      </Flex>
    </div>
  );
};

export default FeedbackCard;
