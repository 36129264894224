import { Flex, Loader, Text, Title } from "@mantine/core";
import React from "react";
import ReactECharts from "echarts-for-react";
import EmptyCard from "../EmptyCard/EmptyCard";

const MeetingsDistBarCardNew = ({
  title,
  data,
  xAxis,
  isLoading,
  isError,
  type = "totalMeetings",
  xAxisLabel,
  yAxisLabel,
  minYAxis = null,
  maxYAxis,
  totalStack = true,
}) => {
  const option = React.useMemo(() => {
    if (data) {
      const series = () => {
        return [
          {
            name: "External",
            type: "bar",
            stack: totalStack && "total",
            data: xAxis.map((value) => data[value]?.["external"]?.[type]),
            itemStyle: {
              color: "#fab005",
            },
          },
          {
            name: "Internal",
            type: "bar",
            stack: totalStack && "total",
            data: xAxis.map((value) => data[value]?.["internal"]?.[type]),
            itemStyle: {
              color: "#39a8f0",
            },
            show: false,
          },
        ];
      };

      return {
        xAxis: {
          data:
            xAxisLabel === "Days" ? xAxis.map((day) => day.slice(0, 3)) : xAxis,
          name: xAxisLabel,
          nameLocation: "middle",
          nameTextStyle: {
            padding: 15,
          },
          axisLabel: {
            interval: 0,
          },
        },
        yAxis: {
          type: "value",
          name: yAxisLabel,
          nameLocation: "middle",
          nameTextStyle: {
            padding: 15,
          },
          axisLabel: {
            interval: 1, // This will show all the values 1, 2, 3, 4, 5 on the y-axis
          },
          min: minYAxis, // Set the minimum value to 1
          max: maxYAxis,
        },
        series: series(),
        grid: {
          top: "10%",
          right: "5%",
          bottom: "20%",
          left: "9%",
        },
        tooltip: {
          trigger: "axis",
        },
      };
    }
  }, [data, type, minYAxis, maxYAxis, xAxis, xAxisLabel, yAxisLabel]);

  if (isLoading) {
    return (
      <Wrapper title={title}>
        <div className="loader">
          <Loader />
          <span>Fetching meeting distribution</span>
        </div>
      </Wrapper>
    );
  }
  if (isError) {
    return (
      <Wrapper title={title}>
        <div className="loader">
          {data?.message || <span>Error while fetching time distribution</span>}
        </div>
      </Wrapper>
    );
  }

  if (!data) {
    return (
      <Wrapper title={title}>
        <div className="loader">
          <EmptyCard />
        </div>
      </Wrapper>
    );
  }
  return (
    <Wrapper title={title}>
      <ReactECharts
        option={option}
        style={{ height: "100%", width: "100%", alignSelf: "center" }}
        opts={{ renderer: "svg" }}
      />
    </Wrapper>
  );
};

export default MeetingsDistBarCardNew;
const Wrapper = ({ children, title }) => {
  return (
    <div className="card" style={{ height: "100%" }}>
      <Flex direction={"column"} sx={{ width: "100%" }}>
        <Flex direction={"column"}>
          <Title order={5}>{title}</Title>
          <Text c="dimmed" fz={"15px"}>
            external & internal
          </Text>
        </Flex>
        {children}
      </Flex>
    </div>
  );
};
