import { Title } from "@mantine/core";
import { hideNotification } from "@mantine/notifications";
import React from "react";
import { useNavigate } from "react-router-dom";

const AccessNotification = ({ info }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    hideNotification(info?.uid);
    navigate("/actions/shared-actions");
  };
  return (
    <div style={{ cursor: "pointer" }} onClick={handleClick}>
      <Title order={6}>{info?.name} has shared his actions</Title>
      <span style={{ fontSize: "12px" }}>
        You have the read-only access to his/her public actions
      </span>
    </div>
  );
};

export default AccessNotification;
