import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useExtensioniFrameListener = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("message", iFramePayloadHandler);
    return () => {
      window.removeEventListener("message", iFramePayloadHandler);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const iFramePayloadHandler = (event) => {
    try {
      const payload = event.data;

      if (typeof payload !== "object") {
        return;
      }

      // console.log("iFrame parent event payload received:", payload);

      const eventAction = payload.action;
      let path = null;

      switch (eventAction) {
        case "gaintime_event_route":
          path = `/extension/event/${payload.eventId}`;
          break;

        case "gaintime_agenda_route":
          path = `/extension/agenda`;
          break;

        // case "gaintime_email_action_item_route":
        //   path = `/extension/gmail-iframe`;
        //   break;

        default:
          return;
      }

      // console.log("Navigating to path:", path);

      navigate(path, { replace: true, state: { params: payload.params } });
    } catch (err) {
      console.error("iFrame payload receiving error:", err.message);
    }
  };
};

export default useExtensioniFrameListener;
