import { Title } from "@mantine/core";
import { hideNotification } from "@mantine/notifications";
import { truncate } from "lodash";
import { useNavigate } from "react-router-dom";

const OutboxNotification = ({ html, type, id, task }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    hideNotification(id);
    navigate("/actions/outbox");
  };
  return (
    <div style={{ cursor: "pointer" }} onClick={handleClick}>
      <Title order={6}>From {task?.assigned?.by}</Title>
      {type === "rejected" && (
        <span style={{ fontSize: "12px" }}>Declined your action item</span>
      )}
      {type === "accepted" && (
        <span style={{ fontSize: "12px" }}>Accepted your action item</span>
      )}
      {type === "completed" && (
        <span style={{ fontSize: "12px" }}>Completed your action item</span>
      )}
      <br />
      <span style={{ color: "black" }}>{truncate(html, { length: 100 })}</span>
    </div>
  );
};

export default OutboxNotification;
