import { Card, Flex, Loader, Text, Title } from "@mantine/core";
import EventRating from "components/Workspace/EventRating/EventRating";
import AttendeeModal from "components/Workspace/Meeting/AttendeeModal/AttendeeModal";
import Cost from "components/Workspace/Meeting/Cost/Cost";
import HasAttended from "components/Workspace/Meeting/HasAttended/HasAttended";
import MeetingTagsDropdownNew from "components/Workspace/Meeting/MeetingTagsDropdown/MeetingTagsDropdownNew";
import StrategicGoal from "components/Workspace/Meeting/StrategicGoal/StrategicGoal";
import useMeeting from "hooks/useMeeting";
import { has } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BsPeopleFill } from "react-icons/bs";
import { MdWatchLater } from "react-icons/md";
import teamStore from "stores/useTeamStore";
import { checkForIncoming } from "utils/events";
const ListEvent = ({
  event,
  metaData,
  tags,
  user,
  refreshMetaData,
  metaDataLoading,
}) => {
  const { calendarEvent, start, end, title } = event;
  const [selectedTag, setSelectedTag] = useState("");
  const [goal, setGoal] = useState(null);
  const { strategicGoals } = teamStore.getState();
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState({
    feedbackTags: [],
    customFeedback: null,
  });
  const [hasAttended, setHasAttended] = useState(true);
  const { submitTag, submitGoal, strategicGoal } = useMeeting(event?.id);
  const [attendeeModalOpen, setAttendeeModalOpen] = useState(false);
  useEffect(() => {
    const filtered = metaData.filter((data) => data.id === event?.id);
    if (filtered.length > 0) {
      setRating(filtered[0]?.rating);
      setFeedback(filtered[0]?.feedback);
      setSelectedTag(filtered[0]?.tag);
      setHasAttended(filtered[0]?.hasAttended);
    }
  }, [event, metaData, strategicGoals, user]);

  useEffect(() => {
    if (user?.subscription?.plan === "ENTERPRISE") {
      setGoal(strategicGoal);
    }
  }, [strategicGoal]);
  console.log(hasAttended)
  // const { hovered, ref } = useHover();
  return (
    <>
      <Card
        padding="lg"
        radius="md"
        px={"xl"}
        pl={"sm"}
        sx={{
          overflow: "visible",
          transition: "all 200ms ease-in",
          cursor: "pointer",
          position: "relative",
        }}
      >
        <Flex w={"100%"} justify={"space-between"}>
          {/* left side */}
          <Flex direction={"column"} gap={".5rem"} sx={{ flex: 1 }}>
            {/* title of event */}
            <Title order={5} fw={500} fz={"1.0rem"}>
              {title}
            </Title>

            <Flex gap={"2rem"}>
              {/* time */}
              <Flex align={"center"} gap={".5rem"}>
                <MdWatchLater color="#39a8f0" size={20} />
                <Text fz={".9rem"}>
                  {moment(start).format("hh:mm A")} -{" "}
                  {moment(end).format("hh:mm A")}
                </Text>
              </Flex>

              {/* guests */}
              <Flex
                align={"center"}
                gap={".5rem"}
                onClick={() => setAttendeeModalOpen(true)}
              >
                <BsPeopleFill color="#39a8f0" size={20} />
                <Text fz={".9rem"}>
                  {calendarEvent?.attendees?.length || 0} Guests
                </Text>
              </Flex>
              <AttendeeModal
                opened={attendeeModalOpen}
                setOpened={setAttendeeModalOpen}
                organizer={calendarEvent?.organizer}
                attendees={calendarEvent?.attendees || []}
              />

              <Cost {...{ calendarEvent, start, end }} />
            </Flex>

            {/* has Attended */}
            <HasAttended
              hasAttended={hasAttended}
              setHasAttended={setHasAttended}
              selectedEventId={event?.id}
            />
          </Flex>

          {/* righ side */}
          {metaDataLoading ? (
            <Flex align={"center"} justify={"center"} sx={{ flex: 1 }}>
              <Loader variant="dots" sx={{ marginLeft: "6rem" }} />
            </Flex>
          ) : (
            <Flex gap={"4rem"} align={"center"}>
              {user?.subscription?.plan === "ENTERPRISE" && (
                <StrategicGoal
                  selectedGoal={goal}
                  setSelectedGoal={setGoal}
                  goals={strategicGoals}
                  selectedEvent={{
                    ...event?.calendarEvent,
                    eventId: event?.id,
                  }}
                  submitGoal={submitGoal}
                  orgId={user?.subscription?.organization_id}
                  refreshMetaData={refreshMetaData}
                />
              )}

              {/* tag */}
              {/* <MeetingTagsDropdown
                key={event?.id}
                tagOptions={tags}
                currentTag={selectedTag}
                handleOnClick={(tagOption) => {
                  setSelectedTag(tagOption);
                  submitTag(tagOption, event?.id);
                }}
              /> */}
              <MeetingTagsDropdownNew
                key={event?.id}
                tagOptions={tags}
                currentTag={selectedTag}
                handleOnClick={(tagOption) => {
                  setSelectedTag(tagOption);
                  submitTag(tagOption, event?.id);
                }}
              />

              {/* event rating */}
              <div
                align={"center"}
                gap="md"
                onClick={(e) => {
                  e.stopPropagation();
                  checkForIncoming(event, hasAttended);
                }}
              >
                <EventRating
                  rating={rating}
                  setRating={setRating}
                  eventId={event?.id}
                  feedback={feedback}
                  readOnly={
                    event?.hasEventEnded || event?.isOngoing ? false : true
                  }
                  currentTag={selectedTag}
                  tagOptions={tags}
                  handleOnTagClick={(tagOption) => {
                    setSelectedTag(tagOption);
                    submitTag(tagOption, event?.id);
                  }}
                  meetingTitle={title}
                  hasAttended={hasAttended}
                />
              </div>
            </Flex>
          )}
        </Flex>
      </Card>
    </>
  );
};

export default ListEvent;
