import React from "react";
import { Tooltip as MantineTooltip } from "@mantine/core";
const Tooltip = ({ children, label, width = "auto", style }) => {
  return (
    <MantineTooltip
      openDelay={100}
      position="bottom"
      transition="scale"
      transitionDuration={300}
      label={label}
      style={{ background: "rgba(0, 0, 0, 0.70)" }}
      multiline
      withArrow
      width={width}
      sx={style}
    >
      {children}
    </MantineTooltip>
  );
};

export default Tooltip;
