import { create } from "zustand";
const useActionsStore = create((set) => ({
  loading: false,
  error: false,
  actionItems: [],
  outboxItems: [],
  sharedAccessList: [],
  outboxLoading: false,
  outboxError: false,
  sharedAccessLoading: false,
  sharedAccessError: false,
  setActionItems: (data) =>
    set(() => ({
      actionItems: data,
    })),
  setLoading: (status) =>
    set(() => ({
      loading: status,
    })),
  setError: (status) =>
    set(() => ({
      error: status,
    })),
  setOutboxItems: (data) =>
    set(() => ({
      outboxItems: data,
    })),
  setOutboxLoading: (status) =>
    set(() => ({
      outboxLoading: status,
    })),
  setOutboxError: (status) =>
    set(() => ({
      outboxError: status,
    })),
  setSharedAccessList: (data) =>
    set(() => ({
      sharedAccessList: data,
    })),
  setSharedAccessLoading: (status) =>
    set(() => ({
      sharedAccessLoading: status,
    })),
  setSharedAccessError: (status) =>
    set(() => ({
      sharedAccessError: status,
    })),
}));
export default useActionsStore;
