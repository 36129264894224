import {
  Drawer,
  FocusTrap,
  Input,
  ScrollArea,
  Select,
  Text,
} from "@mantine/core";
import css from "./ShareAccessDrawer.module.scss";
import teamStore from "stores/useTeamStore";
import { BsFillPersonPlusFill, BsPersonCheckFill } from "react-icons/bs";
import { useEffect, useState } from "react";
import { IoIosRemoveCircle } from "react-icons/io";
import Tooltip from "components/Tooltip/Tooltip";
import { AiOutlineSearch } from "react-icons/ai";
import { getTeamOptions, notify } from "utils/common";
import { openConfirmModal } from "@mantine/modals";
import useSharedActions from "hooks/useSharedActions";
import { useUser } from "stores/useUser";
import { useAutoAnimate } from "@formkit/auto-animate/react";
function ShareAccessDrawer({ opened, setOpened }) {
  const { team } = teamStore.getState();
  const [data, setData] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [parent] = useAutoAnimate();

  const { addAccess, actionSharedWith, loading, error, removeAccess } =
    useSharedActions();
  const [filterFocus, setFilterFocus] = useState(false);
  const { user } = useUser();
  // for getting selectable options: removing the people from options who already have access
  useEffect(() => {
    const options = getTeamOptions();
    const actionsSharedWithIds = actionSharedWith.map((person) => person?.id);
    setData(
      options.filter(
        (option) =>
          !actionsSharedWithIds.includes(option?.value) &&
          option?.value !== user?.email
      )
    );
  }, [team, actionSharedWith, user]);

  useEffect(() => {
    setFiltered(actionSharedWith);
  }, [actionSharedWith]);

  useEffect(() => {
    if (filterValue !== "") {
      const results = actionSharedWith.filter((user) => {
        return (
          user.name.toLowerCase().includes(filterValue.toLowerCase().trim()) ||
          user.id.toLowerCase().includes(filterValue.toLowerCase().trim())
        );
        // Use the toLowerCase() method to make it case-insensitive
      });
      setFiltered(results);
    } else {
      setFiltered(actionSharedWith);
    }
  }, [filterValue, setFiltered, actionSharedWith]);

  const handleSelect = (id) => {
    setFilterFocus(false);
    openConfirmModal({
      title: "Please confirm your action",
      children: (
        <Text size="sm">
          This will give the ready-only access of your public action items to{" "}
          <b>{id}</b>
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => handleGiveAccess(id),
    });
  };
  const handleGiveAccess = async (id) => {
    try {
      const person = data.filter((person) => person.value === id)[0];
      await addAccess(person);
      setFilterFocus(true);
    } catch (e) {
      console.log(e);
      notify("Error occured while sharing", "error");
    }
  };

  const handleRemove = (person) => {
    openConfirmModal({
      title: "Please confirm your action",
      children: (
        <Text size="sm">
          This person will no longer be able to see your public action items
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => handleRemoveAccess(person),
    });
  };

  const handleRemoveAccess = async (person) => {
    try {
      await removeAccess(person);
    } catch (e) {
      console.log(e);
      notify("Error occured while removing", "error");
    }
  };
  return (
    <>
      <Drawer
        opened={opened}
        onClose={() => setOpened(false)}
        position="right"
        size={"xl"}
      >
        <div className={css.container}>
          <div className={css.head}>
            <span className={css.title}>Share access to your actions</span>
            <span className={css.des}>
              NOTE: Users with whom you share access will only have read-only
              access to your action items that are designated as public
            </span>
          </div>

          <div className={css.body}>
            <Select
              icon={<BsFillPersonPlusFill size={20} color="#39a8f0" />}
              placeholder="Select team member to share access"
              data={data}
              searchable
              maxDropdownHeight={400}
              nothingFound="Nobody here"
              filter={(value, item) =>
                item.label.toLowerCase().includes(value.toLowerCase().trim()) ||
                item.value.toLowerCase().includes(value.toLowerCase().trim())
              }
              onChange={handleSelect}
              value={""}
            />
            <div className={css.listContainer}>
              <div className={css.title}>
                Members having your public actions access
              </div>
              <div className={css.filter}>
                <FocusTrap active={filterFocus}>
                  <Input
                    type="text"
                    value={filterValue}
                    onChange={(e) => setFilterValue(e.target.value)}
                    icon={<AiOutlineSearch size={18} color="grey" />}
                    placeholder="Search"
                  />
                </FocusTrap>
              </div>
              <ScrollArea className={css.list}>
                <div className={css.listContainer} ref={parent}>
                  {filtered?.length > 0 ? (
                    filtered.map((person, i) => {
                      return (
                        <div className={css.person} key={i}>
                          <div className={css.detail}>
                            <div className={css.tick}>
                              <BsPersonCheckFill
                                color="rgb(18, 184, 134)"
                                size={20}
                              />
                            </div>
                            <div className={css.name}>
                              <span>{person?.name}</span>
                              <span>{person?.id}</span>
                            </div>
                          </div>
                          <Tooltip label={"Remove access"}>
                            <div
                              className={css.remove}
                              onClick={() => handleRemove(person)}
                            >
                              <IoIosRemoveCircle size={23} color="#f44336" />
                            </div>
                          </Tooltip>
                        </div>
                      );
                    })
                  ) : (
                    <span>Shared with no one</span>
                  )}
                </div>
              </ScrollArea>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default ShareAccessDrawer;
