import { Outlet } from "react-router-dom";

import Header from "components/Header/Header";
import usePrefetch from "hooks/insights/usePrefetch";
import useFirebaseActions from "hooks/useFirebaseActions";
import { useUser } from "stores/useUser";
import { useEffect } from "react";
import { doc, setDoc } from "firebase/firestore";
import { FirestoreDB } from "utils/firebase";

const Layout = (source) => {
  const { user } = useUser();
  // for now using this prefetch for insights
  usePrefetch(source);

  // to have the actions in global state and be able to recieve notifications at any page in app
  useFirebaseActions();

  // setting access time to db
  useEffect(() => {
    const handleLastTimeAccess = async () => {
      await setDoc(
        doc(FirestoreDB, "users", user.uid),
        { lastTimeAccessed: new Date() },
        { merge: true }
      );
    };
    handleLastTimeAccess();
  }, [user]);
  if (source === "extension") {
    return (
      <>
        <Outlet />
      </>
    );
  }

  return (
    <>
      <Header />
      <div style={{ marginTop: "4.5rem" }}>
        <Outlet />
      </div>
    </>
  );
};

export default Layout;
