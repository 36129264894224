import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyCdkfOuHC1dMOxcnsWsjYm8hhgV_GG2O9A",
  authDomain: "gaintime-website.firebaseapp.com",
  projectId: "gaintime-website",
  storageBucket: "gaintime-website.appspot.com",
  messagingSenderId: "436769416051",
  appId: "1:436769416051:web:b868b319378f2090f5db06",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);

// connectFunctionsEmulator(functions, "127.0.0.1", 5001)
const USER_SCOPES = [
  "https://www.googleapis.com/auth/calendar",
  "https://www.googleapis.com/auth/gmail.send",
];

const firebaseSignInWithGoogle = async (withScopes = true, prompt = false) => {
  try {
    const provider = new GoogleAuthProvider();

    if (withScopes) {
      USER_SCOPES.map((scope) => {
        provider.addScope(scope);
      });

      console.log(prompt);

      if (prompt) {
        provider.setCustomParameters({
          app_name: "gaintime",
          access_type: "offline",
          prompt: "consent", // default opens consent for new users.
        });
      } else {
        provider.setCustomParameters({
          app_name: "gaintime",
          access_type: "offline",
        });
      }
    }

    const res = await signInWithPopup(auth, provider);
    return res;
  } catch (e) {
    console.log(e);
    if (
      e.message.includes("Error getting verification code from google.com") &&
      !prompt
    ) {
      return await firebaseSignInWithGoogle(withScopes, true);
    } else {
      return null;
    }
  }
};

export {
  auth as FirebaseAuth,
  db as FirestoreDB,
  functions as CloudFunctions,
  firebaseSignInWithGoogle,
};
