import { Box, Card, Flex, Text } from "@mantine/core";
import React from "react";
import { BsFillCalendarRangeFill } from "react-icons/bs";
import ListEvent from "./ListEvent";
import { useUser } from "stores/useUser";
import moment from "moment";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import css from './EventList.module.scss'
const EventList = ({
  date,
  data,
  metaData,
  metaDataLoading,
  targetRef,
  refreshMetaData,
}) => {
  const { user, tags } = useUser();
  const [parent] = useAutoAnimate(/* optional config */);
  const isToday = () => {
    const today = moment(new Date()).format("DD.MM.YYYY");
    if (String(today) === String(moment(date).format("DD.MM.YYYY"))) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Flex direction={"column"} gap={"1rem"} ref={parent}>
      {/* header */}
      <Card
        // shadow="sm"
        w={"100%"}
        radius="md"
        withBorder
        ref={isToday() ? targetRef : null}
        sx={{ position: "sticky", zIndex: 10, color: "white" }}
        top={0}
        bg="#838f99"
      >
        <Flex direction={"column"} px={"1rem"} gap={".5rem"}>
          <Flex align={"baseline"} gap={"1rem"}>
            <BsFillCalendarRangeFill size={"1rem"} />
            {isToday() ? (
              <Flex align={"baseline"} gap={"1rem"}>
                <Text fz={"1rem"} fw={"bold"}>
                  Today
                </Text>
                <Text fz={".9rem"} fw={500}>
                  {moment(date).format("ddd DD MMM")}
                </Text>
              </Flex>
            ) : (
              <Text fz={"1rem"} fw={"bold"}>
                {moment(date).format("ddd DD MMM")}
              </Text>
            )}
          </Flex>

          <Flex
            sx={{ fontWeight: 600, fontSize: "1rem" }}
            w={"100%"}
            align={"center"}
            justify={"space-between"}
          >
            <Text>Meeting Details</Text>
            <Box>
              <Flex gap={"7rem"}>
                {user?.subscription?.plan === "ENTERPRISE" && (
                  <Text>Goals</Text>
                )}
                <Text>Tags</Text>
                <Text>Effectiveness</Text>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Card>

      <Card w={"100%"} radius="md" withBorder className={css.container}>
        {data.map((event, i) => (
          <ListEvent
            event={event}
            metaData={metaData}
            tags={tags}
            user={user}
            key={i}
            refreshMetaData={refreshMetaData}
            metaDataLoading={metaDataLoading}
          />
        ))}
      </Card>
    </Flex>
  );
};

export default EventList;
