import { Container, Flex, Image, Text } from "@mantine/core";
import React from "react";
import Logo from "assets/logo.png";
import Gmail from "assets/gmail.png";
import Slack from "assets/slack.png";
import { TbArrowsExchange } from "react-icons/tb";
const ModalWrapper = ({ showIcon = true, type, children }) => {
  return (
    <Flex direction={"column"} gap={"1rem"} position="relative">
      {showIcon && (
        <Flex gap={"2rem"} align={"center"} width="100%" justify={"center"}>
          <Image
            src={Logo}
            alt="logo image"
            width={130}
            height={50}
            fit="contain"
          />

          {type && <TbArrowsExchange size={30} color="grey" />}

          {type === "email" ? (
            <Image
              src={Gmail}
              alt="logo image"
              width={40}
              height={50}
              fit="contain"
            />
          ) : type === "slack" ? (
            <Image
              src={Slack}
              alt="logo image"
              width={40}
              height={50}
              fit="contain"
            />
          ) : null}
        </Flex>
      )}
      <div style={{ marginBottom: "2rem" }}>{children}</div>

      <Flex gap={".4rem"} pos={"absolute"} bottom={"2rem"}>
        <Text fz={"10px"} inline>
          Powered By
        </Text>
        <Text fz={12} fw={"bold"} inline>
          GAINTIME
        </Text>
      </Flex>
      <Container
        sx={{
          marginTop: "2rem",
          width: "100%",
          height: 10,
          background: "black",
          position: "absolute",
          bottom: 0,
          left: 0,
          borderRadius: "0px 0px 5px 5px",
          background:
            "linear-gradient(266deg, #2BDFF8 10%, rgb(75 17 245 / 48%) 98.58%)",
        }}
      />
    </Flex>
  );
};

export default ModalWrapper;
