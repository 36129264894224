import MeVsMeetingCard from "components/Insights/MeVsMeetingCard/MeVsMeetingCard";
import React, { useMemo } from "react";
import css from "../MeetingTime/AsAttendee/AsAttendee.module.scss";
import { Flex, Loader, Switch, Text } from "@mantine/core";
import useInsights from "hooks/insights/useInsights";
import useAnalyticsStore from "stores/useAnalyticsStore";
import { formatDuration } from "utils/insights";
import { useToggle } from "@mantine/hooks";
import CostCard from "components/Insights/CostCard/CostCard";
import EffectivenessCard from "components/Insights/EffectivenessCard/EffectivenessCard";
import SplitCard from "components/Insights/SplitCard/SplitCard";
import MeetingsDistBarCardNew from "components/Insights/MeetingsDistBarCardNew/MeetingsDistBarCardNew";
import FeedbackCard from "components/Insights/FeedbackCard/FeedbackCard";
import LineCard from "components/Insights/EffectivenessLineCard/LineCard";
import CustomFeedbackCard from "components/Insights/CustomFeedbackCard/CustomFeedbackCard";

const MyOrg = () => {
  const [self, setSelf] = useToggle(["ExcludingSelf", "IncludingSelf"]);
  const { myOrgData, isMyOrgLoading, isMyOrgError } = useInsights();
  const { duration } = useAnalyticsStore();
  const minutesBuckets = [
    "lessThan15",
    "15-30",
    "31-60",
    "61-120",
    "moreThan120",
  ];
  const attendeesBuckets = ["1", "2-5", "6-10", "11-20", "20+"];
  const weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const effectivenessLineCardData = useMemo(() => {
    if (!isMyOrgLoading && !isMyOrgError) {
      const data =
        myOrgData?.["meetingDistByWeekday"]?.[formatDuration(duration)]?.[self] || {};
      const xAxisData = () => {
        // Convert the object properties into an array of objects
        const dataArray = Object.entries(data).map(([intervalType, info]) => ({
          intervalType,
          ...info,
        }));

        // Sort the array based on the 'position' property
        dataArray.sort((a, b) => a.position - b.position);

        // Extract the day names in the sorted order
        const sorted = dataArray.map((item) => item.intervalType);

        return sorted.reverse();
      };
      return {
        xAxisData: xAxisData(),
        series: [
          {
            name: "External",
            type: "line",
            data: xAxisData().map(
              (interval) =>
                data?.[interval]?.external?.averageRatingsNotByMyHierarchy
            ),
            itemStyle: {
              color: "#39a8f0",
            },
          },
          {
            name: "Internal",
            type: "line",
            data: xAxisData().map(
              (interval) =>
                data?.[interval]?.internal?.averageRatingsNotByMyHierarchy
            ),
            itemStyle: {
              color: "#fab005",
            },
          },
        ],
      };
    }
  }, [duration, myOrgData, self, isMyOrgLoading, isMyOrgError]);

  const customFeedbackData = useMemo(() => {
    let feedbacks = [];
    if (!isMyOrgLoading && !isMyOrgError) {
      const data =
        myOrgData?.["mostProvidedFeedbacks"]?.[formatDuration(duration)]?.[
          self
        ]?.["custom_feedback"] || {};
      Object.keys(data).map((feedback, i) => {
        feedbacks.push({
          feedback,
          meetingId: data?.[feedback]?.["meetings_details"][0]?.["meeting_id"],
          title: data?.[feedback]?.["meetings_details"][0]?.[
            "meeting_title"
          ],
          organizer:
            data?.[feedback]?.["meetings_details"][0]?.["organizer_id"],
          startTime:
            data?.[feedback]?.["meetings_details"][0]?.["meeting_timings"]?.[
              "start_time"
            ],
          endTime:
            data?.[feedback]?.["meetings_details"][0]?.["meeting_timings"]?.[
              "end_time"
            ],
          date: data?.[feedback]?.["meetings_details"][0]?.["meeting_date"],
          attendees: data?.[feedback]?.["meetings_details"][0]?.["attendees"],
        });
      });
    }
    return feedbacks;
  }, [duration, myOrgData, self, isMyOrgLoading, isMyOrgError]);

  if (isMyOrgError) {
    return <span>Error while loading insights</span>;
  }
  if (isMyOrgLoading) {
    <div className="loader">
      <Loader />
      <Text>Loading insights ...</Text>
    </div>;
  }
  return (
    <div className={css.container} style={{ marginBottom: "1rem" }}>
      <Flex align={"center"} gap={20} justify={"flex-end"}>
        <Text fz={15} w={500} ta={"end"}>
          {self}
        </Text>
        <Switch onChange={setSelf} />
      </Flex>
      <div className={css.row}>
        <CostCard
          type="myOrg"
          isLoading={isMyOrgLoading}
          isError={isMyOrgError}
          data={myOrgData?.["meetingCost"]?.[formatDuration(duration)]?.[self]}
        />

        <MeVsMeetingCard
          type="myOrg"
          isLoading={isMyOrgLoading}
          isError={isMyOrgError}
          data={
            myOrgData?.["meVsMeetingTime"]?.[formatDuration(duration)]?.[self]
          }
        />

        <EffectivenessCard
          type="myOrg"
          isLoading={isMyOrgLoading}
          isError={isMyOrgError}
          data={
            myOrgData?.["meetingEffectiveness"]?.[formatDuration(duration)]?.[
              self
            ]
          }
        />
      </div>

      {/* third row */}
      <div className={css.row}>
        <FeedbackCard
          data={
            myOrgData?.["mostProvidedFeedbacks"]?.[formatDuration(duration)]?.[
              self
            ]?.feedback_tag || {}
          }
          isLoading={isMyOrgLoading}
          isError={isMyOrgError}
        />
        <SplitCard
          data={
            myOrgData?.["strategicFocus"]?.[formatDuration(duration)]?.[self] || {}
          }
          isLoading={isMyOrgLoading}
          isError={isMyOrgError}
          title="Strategic Focus (in hrs)"
        />
        <SplitCard
          data={
            myOrgData?.["operationalFocus"]?.[formatDuration(duration)]?.[self] || {}
          }
          isLoading={isMyOrgLoading}
          isError={isMyOrgError}
          title="Operational Focus (in hrs)"
        />
      </div>

      <div className={css.row} style={{ height: "18rem" }}>
        <LineCard
          isLoading={isMyOrgLoading}
          isError={isMyOrgError}
          xAxisData={effectivenessLineCardData?.xAxisData}
          series={effectivenessLineCardData?.series}
          rotate={-45}
          yAxisMax={5}
          title="Effectiveness over time"
        />
        <CustomFeedbackCard
          data={customFeedbackData}
          isLoading={isMyOrgLoading}
          isError={isMyOrgError}
        />
      </div>

      {/* fifth row */}
      <div className={css.row}>
        <div style={{ height: "20rem" }}>
          <MeetingsDistBarCardNew
            data={
              myOrgData?.["meetingsDistribution"]?.[formatDuration(duration)]?.[
                self
              ]
            }
            isLoading={isMyOrgLoading}
            isError={isMyOrgError}
            title="Meetings Distribution"
            type="totalMeetings"
            xAxis={minutesBuckets}
            xAxisLabel={"Minutes"}
            yAxisLabel={"Meetings"}
            minYAxis={0}
          />
        </div>

        <div style={{ height: "20rem" }}>
          <MeetingsDistBarCardNew
            data={
              myOrgData?.["meetingsDistribution"]?.[formatDuration(duration)]?.[
                self
              ]
            }
            isLoading={isMyOrgLoading}
            isError={isMyOrgError}
            title="Meetings Effectiveness"
            type="averageRating"
            xAxis={minutesBuckets}
            xAxisLabel={"Minutes"}
            yAxisLabel={"Effectiveness"}
            minYAxis={0}
            maxYAxis={5}
            totalStack={false}
          />
        </div>
      </div>

      <div className={css.row}>
        <div style={{ height: "20rem" }}>
          <MeetingsDistBarCardNew
            data={
              myOrgData?.["meetingDistBySize"]?.[formatDuration(duration)]?.[
                self
              ]
            }
            isLoading={isMyOrgLoading}
            isError={isMyOrgError}
            title="Dist of mtgs by size"
            type="totalMeetings"
            xAxis={attendeesBuckets}
            xAxisLabel={"No. of attendees"}
            yAxisLabel={"Meetings"}
            minYAxis={0}
          />
        </div>
        <div style={{ height: "20rem" }}>
          <MeetingsDistBarCardNew
            data={
              myOrgData?.["meetingDistBySize"]?.[formatDuration(duration)]?.[
                self
              ]
            }
            isLoading={isMyOrgLoading}
            isError={isMyOrgError}
            title="Effectiveness of mtgs by size"
            type="averageRating"
            xAxis={attendeesBuckets}
            xAxisLabel={"No. of attendees"}
            yAxisLabel={"Effectiveness"}
            minYAxis={0}
            totalStack={false}
          />
        </div>
      </div>

      <div className={css.row}>
        <div style={{ height: "20rem" }}>
          <MeetingsDistBarCardNew
            data={
              myOrgData?.["meetingDistByWeekday2"]?.[
                formatDuration(duration)
              ]?.[self]
            }
            isLoading={isMyOrgLoading}
            isError={isMyOrgError}
            title="Dist of mtgs over time"
            type="totalMeetings"
            xAxis={weekdays}
            xAxisLabel={"Days"}
            yAxisLabel={"Meetings"}
            minYAxis={0}
          />
        </div>
        <div style={{ height: "20rem" }}>
          <MeetingsDistBarCardNew
            data={
              myOrgData?.["meetingDistByWeekday2"]?.[
                formatDuration(duration)
              ]?.[self]
            }
            isLoading={isMyOrgLoading}
            isError={isMyOrgError}
            title="Effectiveness of mtgs by day"
            type="averageRatingByMyHierarchy"
            xAxis={weekdays}
            xAxisLabel={"Days"}
            yAxisLabel={"Effectiveness"}
            minYAxis={0}
            maxYAxis={5}
            totalStack={false}
          />
        </div>
      </div>
    </div>
  );
};

export default MyOrg;
