import { MeetingDetails } from "api";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import moment from "moment";
import { useEffect, useState } from "react";
import useCalendarStore from "stores/useCalendarStore";
import { useUser } from "stores/useUser";
import { parseCalendarEvents } from "utils/calendar";
import { notify } from "utils/common";
import { FirestoreDB } from "utils/firebase";

const useCalendar = () => {
  const { user } = useUser();
  const {
    events,
    setEvents,
    metaData,
    setMetaData,
    loading,
    setLoading,
    error,
    setError,
    metaDataLoading,
    setMetaDataLoading,
    metaDataError,
    setMetaDataError,
    activeDateRange,
    setActiveDateRange,
  } = useCalendarStore();

  const fetchMetaData = async (events) => {
    if (events?.length > 0) {
      try {
        setMetaDataLoading(true);
        const eventIds = events.map((event) => event.id);
        const response = await MeetingDetails(user.email, eventIds);
        setMetaData(response.data);
      } catch (error) {
        notify("Error while fetching metadata", "error");
        setMetaDataError(true);
      } finally {
        setMetaDataLoading(false);
      }
    }
  };

  // * fetching meta data
  useEffect(() => {
    fetchMetaData(events);
  }, [events, setMetaDataError, setMetaDataLoading, setMetaData, user]);

  // * fetching events data
  useEffect(() => {
    if (activeDateRange) {
      setLoading(true);
      const q = query(
        collection(FirestoreDB, `users/${user.uid}/calendarEvents`),
        where(
          "start.dateTime",
          ">=",
          moment(activeDateRange.startDate).format("YYYY-MM-DD") +
            "T00:00:00.000Z"
        ),
        where(
          "start.dateTime",
          "<=",
          moment(activeDateRange.endDate).format("YYYY-MM-DD") +
            "T00:00:00.000Z"
        )
      );
      try {
        const unsubscribeCalendarData = onSnapshot(q, async (querySnapshot) => {
          const res = [];
          querySnapshot.forEach((doc) => {
            res.push(doc.data());
          });
          setEvents(parseCalendarEvents(user, res));
          setLoading(false);
        });

        // * unsubscribe the listener when component unmounts
        return () => {
          console.log("UNSUBSCRIBING CALENDAR EVENTS");
          unsubscribeCalendarData();
        };
      } catch (err) {
        notify("Error while fetching events", "error");
        setLoading(false);
        setError(true);
        console.log(err);
      }
    }
  }, [activeDateRange, user]);

  // * refresh meta data
  const refreshMetaData = () => fetchMetaData(events)

  return {
    events,
    setEvents,
    metaData,
    setMetaData,
    loading,
    setLoading,
    error,
    setError,
    metaDataLoading,
    setMetaDataLoading,
    metaDataError,
    setMetaDataError,
    activeDateRange,
    setActiveDateRange,
    refreshMetaData
  };
};

export default useCalendar;
