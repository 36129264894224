import { uniqBy } from "lodash";
import InboxNotification from "notifications/InboxNotification.jsx";
import OutboxNotification from "notifications/OutboxNotification.jsx";
import { showNotification } from "@mantine/notifications";
import { collection, getDocs, query, where } from "firebase/firestore";
import moment from "moment";
import { AiOutlineExclamation } from "react-icons/ai";
import { FiCheck } from "react-icons/fi";
import teamStore from "stores/useTeamStore";
import { FirestoreDB } from "./firebase";
import { BsFillShareFill } from "react-icons/bs";
import AccessNotification from "notifications/AccessNotification";
import { TbChecklist } from "react-icons/tb";
const { DateTime } = require("luxon");
export const notify = (title, type, message = "", timeout = 3000, id, others) =>
  showNotification({
    id: id,
    autoClose: timeout,
    title: title,
    message,
    color: type === "error" ? "red" : "teal",
    className: "my-notification-class",
    icon:
      type === "error" ? (
        <AiOutlineExclamation />
      ) : type === "success" ? (
        <FiCheck />
      ) : null,
    ...others,
  });

export const getDateDiff = (d1, d2) => {
  const a = moment(d1);
  const b = moment(d2);
  const diffDays = b.diff(a, "minutes");
  return diffDays;
};

export const isSourceExtension = (_url) => {
  // console.log("*** Passed URL:", _url);
  const flag = _url.split("/extension/")[1]?.trim().length > 0 ? true : false;
  // console.log("isSourceExtension:", flag);
  return flag;
};

export const attendeesWithout = (attendees, userAddress) => {
  return attendees.filter((attendee) => attendee.email !== userAddress);
};

export const mentionModuleConfig = {
  allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
  mentionDenotationChars: ["@"],
  source: async function (searchTerm, renderList, mentionChar) {
    let values;
    if (mentionChar === "@") {
      values = teamStore.getState()?.team || [];
    }
    if (searchTerm.length === 0) {
      renderList(values, searchTerm);
    } else {
      const matches = [];
      for (let i = 0; i < values.length; i++)
        if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
          matches.push(values[i]);
      renderList(matches, searchTerm);
    }
  },
};

export const getActionItems = (editorContents, customActionHtml, user) => {
  const items = [];
  const ACTION_TEMPLATE = {
    id: -1,
    completed: false,
    priority: 1,
    effort: 1,
    assigned: {
      by: "",
      rejected: false,
      accepted: false,
    },
    html: "",
    selected: true,
    to_user: {},
  };
  for (var i = 0; i < editorContents?.length; i++) {
    const x = editorContents[i];

    if (x.insert.mention) {
      let newactionitem = {
        ...ACTION_TEMPLATE,
      };
      newactionitem.id = i;
      newactionitem.to_user = x.insert.mention;
      newactionitem.assigned.by = user.email;
      newactionitem.html = customActionHtml;
      items.push(newactionitem);
    }
  }
  return items;
};

export const scanActions = async (currentItems, user) => {
  try {
    const foundedActionItems = [];

    // getting the reference of relevant user
    for (let i = 0; i < currentItems.length; i++) {
      const item = currentItems[i];
      const q = query(
        collection(FirestoreDB, `users`),
        where("email", "==", item?.to_user?.id)
      );
      const querySnapshot = await getDocs(q);
      for (let j = 0; j < querySnapshot.docs.length; j++) {
        const userRef = querySnapshot.docs[j].ref;
        // getting the action Items of the relevant user
        const q = query(
          collection(userRef, "ActionItems"),
          where("html", "==", item.html?.trim()),
          where("assigned.by", "==", user.email)
        );

        const snap = await getDocs(q);
        for (let k = 0; k < snap.docs.length; k++) {
          foundedActionItems.push(item);
        }
      }
    }
    return foundedActionItems;
  } catch (err) {
    console.error(err);
    notify("Error while scanning actions", "error");
  }
};

export function formatNumber(num) {
  if (num < 1000) {
    return parseFloat(num).toFixed(1);
  }
  const units = ["", "K", "M", "B", "T"];
  const unitIndex = Math.floor(Math.log10(num) / 3);
  const unitName = units[unitIndex];
  const shortNum = parseFloat(num / Math.pow(10, unitIndex * 3)).toFixed(1);
  return `${shortNum}${unitName}`;
}

const checkAssignAccepted = (task) => {
  // return all tasks which you have created or if assigned by someone else then accepted should be true and rejected should be false
  if (task?.assigned) {
    if (task?.assigned?.accepted && !task?.assigned?.rejected) {
      return true;
    } else return false;
  } else return true;
};

export const filterTask = (task, type) => {
  if (type === "all") {
    // return all tasks which you have created or if assigned by someone else then accepted should be true and rejected should be false
    if (checkAssignAccepted(task)) return task;
  } else if (type === "pending") {
    if (checkAssignAccepted(task)) return !task.completed;
  } else if (type === "completed") {
    if (checkAssignAccepted(task)) return task.completed;
  } else if (type === "public") {
    return !task?.private;
  } else if (type === "private") {
    return task?.private;
  } else if (type === "inbox") {
    if (task?.assigned) {
      if (
        !task?.assigned?.accepted &&
        !task?.assigned?.rejected &&
        task?.assigned?.by !== "self"
      ) {
        return true;
      }
    }
  }
};

export const filterMeetingsByDate = (meetings, pastDays) => {
  let today = DateTime.local().startOf("day");
  const pastDate = today
    .toLocal()
    .minus({ days: pastDays })
    .startOf("day")
    .toMillis();
  today = today.minus({ second: 1 }).toMillis();

  return meetings.filter((meeting) => {
    // const startDateTime = new Date(meeting.get("start.dateTime"));
    const startDateTime = DateTime.fromISO(meeting.start.dateTime)
      .toLocal()
      .toMillis();
    return startDateTime > pastDate && startDateTime < today;
  });
};

const priority = {
  0: "low",
  1: "medium",
  2: "high",
};

export const formatActions = (actions) => {
  const withoutTrailingTags = actions.map((action) => {
    if (action?.html.startsWith("<p>") && action?.html.endsWith("</p>")) {
      const formattedHtml = action?.html.substring(3, action?.html.length - 4);
      return { ...action, html: formattedHtml };
    } else {
      return action;
    }
  });
  const formattedActions = withoutTrailingTags.map((action, i) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = action.html;
    // Extract the linear text
    const linearText = tempElement.textContent || tempElement.innerText;

    return {
      index: i + 1,
      html: linearText,
      due_date: moment(action.due_date.toDate()).format("DD MMM"),
      priority: priority[action.priority],
      effort: priority[action.effort],
    };
  });
  return formattedActions;
};

export const getTaskStatus = (task) => {
  if (task?.completed) {
    return "Completed";
  }

  if (task?.assigned?.rejected) {
    return "Rejected";
  }

  if (
    !task?.assigned?.rejected &&
    !task?.assigned?.accepted &&
    !task?.completed
  ) {
    return "Sent";
  }

  if (
    !task?.assigned?.rejected &&
    task?.assigned?.accepted &&
    !task?.completed
  ) {
    return "Accepted";
  }

  if (!task?.completed) {
    return "Rejected";
  }
};

export const getSortedList = (items, sortMethod) => {
  const statusOrder = ["Completed", "Accepted", "Rejected", "Sent"];
  switch (sortMethod) {
    case "status asc":
      return items.slice().sort((a, b) => {
        const statusA = getTaskStatus(a);
        const statusB = getTaskStatus(b);
        return statusOrder.indexOf(statusA) - statusOrder.indexOf(statusB);
      });
    case "status desc":
      return items.slice().sort((a, b) => {
        const statusA = getTaskStatus(a);
        const statusB = getTaskStatus(b);
        return statusOrder.indexOf(statusB) - statusOrder.indexOf(statusA);
      });

    case "assigned to asc":
      return items.slice().sort((a, b) => {
        const addressA = a?.assigned?.to;
        const addressB = b?.assigned?.to;
        return addressA.localeCompare(addressB);
      });

    case "assigned to dsc":
      return items.slice().sort((a, b) => {
        const addressA = a?.assigned?.to;
        const addressB = b?.assigned?.to;
        return addressB.localeCompare(addressA);
      });

    case "due date asc":
      return items.slice().sort((a, b) => a.due_date - b.due_date);

    case "due date desc":
      return items.slice().sort((a, b) => b.due_date - a.due_date);

    default:
      return items;
  }
};

export const getTeamOptions = () => {
  const { team } = teamStore.getState();
  if (team !== null) {
    return uniqBy(
      team.map((person) => ({
        value: person?.id,
        label: person?.value,
        uid: person?.uid,
      })),
      "value"
    );
  } else {
    return [];
  }
};

export const checkForInboxNotifications = (change, lastTimeAccessed) => {
  const task = change.doc.data();
  if (change.type === "added") {
    if (task.created_on.toDate() > lastTimeAccessed.toDate()) {
      if (filterTask(task, "inbox")) {
        const formatted = formatActions([task])[0];
        notify(
          null,
          null,
          <InboxNotification
            html={formatted?.html}
            task={task}
            id={change?.doc?.id}
          />,
          10000,
          change?.doc?.id
        );
      }
    }
  }
};

export const checkForOutboxNotifications = (change, lastTimeAccessed, prev) => {
  if (change.type === "modified") {
    const task = change.doc.data();
    if (task?.assigned?.responded_on?.toDate() > lastTimeAccessed.toDate()) {
      console.log(task);
      console.log(change.doc.id);
      const prevTask = prev.filter((task) => task?.id === change?.doc?.id);
      const formatted = formatActions([task])[0];
      console.log(task);
      if (task?.assigned?.accepted === true && !task?.completed_updated_at) {
        notify(
          null,
          "success",
          <OutboxNotification
            html={formatted?.html}
            type="accepted"
            id={change?.doc?.id}
            task={task}
          />,
          10000,
          change?.doc?.id
        );
        return;
      }
      if (task?.assigned?.accepted === true && task?.completed === true) {
        notify(
          null,
          null,
          <OutboxNotification
            html={formatted?.html}
            type={"completed"}
            id={change?.doc?.id}
            task={task}
          />,
          10000,
          change?.doc?.id,
          { icon: <TbChecklist />, color: "blue" }
        );
        return;
      }
      if (!prevTask?.assigned?.rejected && task?.assigned?.rejected) {
        notify(
          null,
          "error",
          <OutboxNotification
            html={formatted?.html}
            type="rejected"
            id={change?.doc?.id}
            task={task}
          />,
          10000,
          change?.doc?.id
        );
        return;
      }
    }
  }
};

export const checkForShareActionsNotification = (change, lastTimeAccessed) => {
  if (change.type === "modified" || change.type === "added") {
    const newEntry = change.doc.data();
    if (newEntry?.created_on?.toDate() > lastTimeAccessed.toDate()) {
      showNotification({
        id: newEntry?.uid,
        message: <AccessNotification info={newEntry} />,
        icon: <BsFillShareFill size={14} />,
        autoClose: 6000,
        style: { cursor: "pointer" },
      });
    }
  }
};
export const ONEDAY = 24 * 60 * 60 * 1000
