import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import moment from "moment";
import { FirestoreDB } from "./firebase";
import { mean, map, countBy, orderBy } from "lodash";
const { DateTime } = require("luxon");

export const getXAxis = (duration) => {
  if (duration === 7) {
    return new Array(7)
      .fill()
      .map((_, i) => moment().subtract(i + 1, "days"))
      .filter((date) => date.day() !== 0 && date.day() !== 6)
      .reverse();
  }
  if (duration === 30) {
    return Array(4)
      .fill()
      .map((_, index) => `week ${index + 1}`);
  }
  if (duration === 90) {
    return Array(13)
      .fill()
      .map((_, index) => `week ${index + 1}`);
  }
};
export const getRatingPoints = (type, duration, data) => {
  if (!data) return;
  // for one week
  if (duration === 7) {
    return getXAxis(duration).map(
      (date, i) =>
        parseFloat(
          data[`${type}`]["7days"][`${moment(date).format("YYYYMMDD")}`]
            ?.averageRatings
        ).toFixed(1) || null
    );
  }
  // for one month duration
  if (duration === 30) {
    const sortedData = {};
    for (let i = 1; i <= Object.keys(data[`${type}`]["28days"]).length; i++) {
      sortedData[`week ${i}`] = data[`${type}`]["28days"][`week_${i}`];
    }
    return Object.keys(sortedData)
      .map((key) => parseFloat(sortedData[key].averageRatings).toFixed(1))
      .reverse();
  }

  // for 3 months duration
  if (duration === 90) {
    const sortedData = {};
    for (let i = 1; i <= Object.keys(data[`${type}`]["91days"]).length; i++) {
      sortedData[`week ${i}`] = data[`${type}`]["91days"][`week_${i}`];
    }
    return Object.keys(sortedData)
      .map((key) => parseFloat(sortedData[key].averageRatings).toFixed(1))
      .reverse();
  }
};

export const getComparisonColor = (value) => {
  return value?.substr(value?.length - 4) === "more" ? "red" : "#58E490";
};

export const getApiDuration = (duration) => {
  return duration === 7 ? "7 days" : duration === 30 ? "28 days" : "91 days";
};

export const getRatingPointsForIndividual = (type, duration, data) => {
  if (!data) return;
  // for one week
  if (duration === 7) {
    return getXAxis(duration).map(
      (date, i) =>
        parseFloat(
          data[`${type}`]["7 days"][`${moment(date).format("YYYYMMDD")}`]
            ?.averageRatings
        ).toFixed(1) || null
    );
  }
  // for one month duration
  if (duration === 30) {
    const sortedData = {};
    for (let i = 1; i <= Object.keys(data[`${type}`]["28 days"]).length; i++) {
      sortedData[`week ${i}`] = data[`${type}`]["28 days"][`week_${i}`];
    }
    return Object.keys(sortedData)
      .map((key) => parseFloat(sortedData[key].averageRatings).toFixed(1))
      .reverse();
  }

  // for 3 months duration
  if (duration === 90) {
    const sortedData = {};
    for (let i = 1; i <= Object.keys(data[`${type}`]["91 days"]).length; i++) {
      sortedData[`week ${i}`] = data[`${type}`]["91 days"][`week_${i}`];
    }
    return Object.keys(sortedData)
      .map((key) => parseFloat(sortedData[key].averageRatings).toFixed(1))
      .reverse();
  }
};

export const getDrComparisonStyles = (value) => {
  if (value >= 0) {
    return "#58E490";
  } else return "red";
};

export const getXAxisForEffDis = (data) => {
  if (!data) return;
  return Object.keys(data);
};

export const getPointsForMeetingDis = (data, type) => {
  return Object.keys(data).map((key) => data[key][type].totalMeetings);
};

export const getPointsForEffDis = (data, type) => {
  let ratings = [];
  Object.keys(data).map((key) => ratings.push(data[key][type]?.ratings));
  const avgRatings = ratings.map((list) => {
    const intList = list.map((value) => parseInt(value));
    return isNaN(mean(intList)) ? 0 : parseFloat(mean(intList)).toFixed(1);
  });
  return avgRatings;
};

// fetching table data
export const fetchOrgMeeting = async (orgId, pastDays = 7) => {
  let today = DateTime.local().startOf("day");
  const pastDate = new Date(
    today.toLocal().minus({ days: pastDays }).startOf("day").toMillis()
  );
  today = new Date(today.minus({ second: 1 }).toMillis());
  try {
    // get all the users who are the part of organization
    const currentOrgUsers = [];
    const usersQuery = query(
      collection(FirestoreDB, `users`),
      where("subscription.organization_id", "==", orgId)
    );
    const querySnapshot = await getDocs(usersQuery);
    for (let i = 0; i < querySnapshot.docs.length; i++) {
      currentOrgUsers.push({
        ...querySnapshot.docs[i].data(),
        credentials: null,
        googleSync: null,
        lastLoginAt: null,
        lastSignInTime: null,
        channel: null,
      });
    }
    // get all the meetings from currentOrgUsers where they are the organizer
    let meetingsWhereCurrentOrgUsersAsOrgnaizer = [];
    for (let i = 0; i < currentOrgUsers.length; i++) {
      const currentUserMeetings = [];
      const q = query(
        collection(
          FirestoreDB,
          `users/${currentOrgUsers[i].uid}/calendarEvents`
        ),
        where("formattedStartTime", ">=", pastDate),
        where("formattedStartTime", "<=", today),
        where("organizer.self", "==", true)
      );
      const snapShot = await getDocs(q);

      for (let j = 0; j < snapShot.docs.length; j++) {
        currentUserMeetings.push(snapShot.docs[j].data());
      }
      meetingsWhereCurrentOrgUsersAsOrgnaizer.push(
        ...currentUserMeetings.filter((meeting) => {
          return (
            meeting?.attendees &&
            meeting?.attendees?.filter((attendee) => {
              return (
                attendee?.email.split("@")[1] !==
                  "resource.calendar.google.com" &&
                attendee?.responseStatus?.toLowerCase() !== "declined"
              );
            })?.length > 1
          );
        })
      );
      // console.log(meetingsWhereCurrentOrgUsersAsOrgnaizer)
    }

    const meetingsWithMetaData = [];

    for (let i = 0; i < meetingsWhereCurrentOrgUsersAsOrgnaizer.length; i++) {
      const meetingId = meetingsWhereCurrentOrgUsersAsOrgnaizer[i].id;
      const meetingRef = doc(FirestoreDB, "meetings", meetingId);
      const usersQuery = query(collection(meetingRef, "users"));

      const meetingSnapshot = await getDoc(meetingRef);
      const meetingData = meetingSnapshot.data();

      const usersSnapshot = await getDocs(usersQuery);
      const usersData = usersSnapshot.docs.map((doc) => doc.data());

      const mergedData = {
        ...meetingData,
        users: usersData,
      };

      meetingsWithMetaData.push(mergedData);
    }

    const mergedData = mergeMeetingsAndMetaData(
      meetingsWhereCurrentOrgUsersAsOrgnaizer,
      meetingsWithMetaData,
      orgId
    );
    // console.log("merged: ", meetingsWithMetaData);
    return mergedData;
    // return meetingsWhereCurrentOrgUsersAsOrgnaizer;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const mergeMeetingsAndMetaData = (meetings, metaData, orgId) => {
  // console.log(meetings, metaData, orgId);
  const merged = meetings.map((meeting, i) => {
    return {
      ...meetings[i],
      cost: parseFloat(metaData[i]?.costInfo?.[`${orgId}`]?.amount).toFixed(1),
      averageRating: getAverateRatingOfMeeting(metaData[i]?.users),
    };
  });
  //would complete if i need
  return merged;
};

const getAverateRatingOfMeeting = (users) => {
  if (users.length > 0) {
    const ratings = users.map((user) =>
      user?.rating ? parseFloat(user?.rating) : 0
    );
    return parseFloat(mean(ratings)).toFixed(1);
  } else return 0;
};

export const getHighestFeedback = (feedbacks) => {
  if (feedbacks && feedbacks.length > 0) {
    if (feedbacks?.length > 0) {
      const groupedArray = orderBy(
        map(countBy(feedbacks), (value, key) => ({
          [key]: value,
        })),
        (value) => Object.values(value)[0]
      );
      return groupedArray.reverse();
    }
  } else return [];
};

export const getOrgMeetingDist = (userEmail, data) => {
  if (data) {
    // format of output
    let output = {
      byLength: {
        lessThan15: {
          internal: {
            totalMeetings: 0,
            totalDuration: 0,
            aboveAverageRating: 0,
            averageRating: 0,
            belowAverageRating: 0,
            ratings: [],
          },
          external: {
            totalMeetings: 0,
            totalDuration: 0,
            aboveAverageRating: 0,
            averageRating: 0,
            belowAverageRating: 0,
            ratings: [],
          },
        },
        "15-30": {
          internal: {
            totalMeetings: 0,
            totalDuration: 0,
            aboveAverageRating: 0,
            averageRating: 0,
            belowAverageRating: 0,
            ratings: [],
          },
          external: {
            totalMeetings: 0,
            totalDuration: 0,
            aboveAverageRating: 0,
            averageRating: 0,
            belowAverageRating: 0,
            ratings: [],
          },
        },
        "31-60": {
          internal: {
            totalMeetings: 0,
            totalDuration: 0,
            aboveAverageRating: 0,
            averageRating: 0,
            belowAverageRating: 0,
            ratings: [],
          },
          external: {
            totalMeetings: 0,
            totalDuration: 0,
            aboveAverageRating: 0,
            averageRating: 0,
            belowAverageRating: 0,
            ratings: [],
          },
        },
        "61-120": {
          internal: {
            totalMeetings: 0,
            totalDuration: 0,
            aboveAverageRating: 0,
            averageRating: 0,
            belowAverageRating: 0,
            ratings: [],
          },
          external: {
            totalMeetings: 0,
            totalDuration: 0,
            aboveAverageRating: 0,
            averageRating: 0,
            belowAverageRating: 0,
            ratings: [],
          },
        },
        moreThan120: {
          internal: {
            totalMeetings: 0,
            totalDuration: 0,
            aboveAverageRating: 0,
            averageRating: 0,
            belowAverageRating: 0,
            ratings: [],
          },
          external: {
            totalMeetings: 0,
            totalDuration: 0,
            aboveAverageRating: 0,
            averageRating: 0,
            belowAverageRating: 0,
            ratings: [],
          },
        },
      },
      bySize: {
        1: {
          internal: {
            totalMeetings: 0,
            totalDuration: 0,
            ratings: [],
          },
          external: {
            totalMeetings: 0,
            totalDuration: 0,
            ratings: [],
          },
        },
        "2-5": {
          internal: {
            totalMeetings: 0,
            totalDuration: 0,
            ratings: [],
          },
          external: {
            totalMeetings: 0,
            totalDuration: 0,
            ratings: [],
          },
        },
        "6-10": {
          internal: {
            totalMeetings: 0,
            totalDuration: 0,
            ratings: [],
          },
          external: {
            totalMeetings: 0,
            totalDuration: 0,
            ratings: [],
          },
        },
        "11-20": {
          internal: {
            totalMeetings: 0,
            totalDuration: 0,
            ratings: [],
          },
          external: {
            totalMeetings: 0,
            totalDuration: 0,
            ratings: [],
          },
        },
        "20+": {
          internal: {
            totalMeetings: 0,
            totalDuration: 0,
            ratings: [],
          },
          external: {
            totalMeetings: 0,
            totalDuration: 0,
            ratings: [],
          },
        },
      },
      feedbacks: [],
    };
    const userEmailSuffix = userEmail?.split("@")[1];

    data.map((meeting) => {
      const startTime = DateTime.fromISO(meeting?.start?.dateTime).toMillis();
      const attendees = meeting?.attendees;
      if (startTime && attendees) {
        const endTime = DateTime.fromISO(meeting?.end?.dateTime).toMillis();
        const duration = (endTime - startTime) / 1000 / 60;
        const duration_category =
          duration <= 15
            ? "lessThan15"
            : duration <= 30
            ? "15-30"
            : duration <= 60
            ? "31-60"
            : duration <= 120
            ? "61-120"
            : "moreThan120";

        const attendeesLength = attendees?.length;
        const attendees_category =
          attendeesLength == 1
            ? "1"
            : attendeesLength <= 5
            ? "2-5"
            : attendeesLength <= 10
            ? "6-10"
            : attendeesLength <= 20
            ? "11-20"
            : "20+";

        let isInternalMeeting = true;
        for (let i = 0; i < attendees?.length; i++) {
          if (
            attendees[i]?.email?.split("@")[1] !== userEmailSuffix &&
            attendees[i]?.responseStatus?.toLowerCase() !== "declined"
          ) {
            isInternalMeeting = false;
            break;
          }
        }

        const meetingType = isInternalMeeting ? "internal" : "external";
        output.byLength[duration_category][meetingType].totalMeetings += 1;
        output.byLength[duration_category][meetingType].totalDuration +=
          duration;

        output.bySize[attendees_category][meetingType].totalMeetings += 1;

        if (meeting?.ratings?.length > 0) {
          meeting?.ratings.map((rating) => {
            const rating_range =
              rating > 4
                ? "aboveAverageRating"
                : rating > 3
                ? "averageRating"
                : "belowAverageRating";
            output.byLength[duration_category][meetingType].ratings.push(
              rating
            );
            output.byLength[duration_category][meetingType][rating_range] +=
              rating;

            output.bySize[attendees_category][meetingType].ratings.push(rating);
          });
        }
      }
    });
    return output;
  }
};

export const getStrategicGoal = (strategicGoals, eventId) => {
  let foundedGoal = null;
  if (strategicGoals) {
    Object.keys(strategicGoals).some((goal) => {
      return strategicGoals[goal]?.events?.some((id) => {
        if (id === eventId) {
          foundedGoal = goal;
          return true;
        }
        return false;
      });
    });
  }
  return foundedGoal || "no goal selected";
};

export const filterAttendees = (attendees) => {
  return attendees?.filter((attendee) => {
    return (
      attendee?.email?.split("@")[1] !== "resource.calendar.google.com" &&
      attendee?.responseStatus?.toLowerCase() !== "declined"
    );
  });
};

export const splitGoals = (strategicGoals, data) => {
  if (data) {
    let split = Object.keys(strategicGoals).map((goal) => ({
      name: goal,
      count: 0,
      meetingHours: 0,
      peopleHours: 0,
    }));
    data?.map((event) => {
      const goal = getStrategicGoal(
        strategicGoals,
        event?.recurringEventId || event?.id
      );
      if (goal) {
        const goalItem = split.find((item) => item.name === goal);
        if (goalItem) {
          // count of meetings for each goal
          goalItem.count++;

          // meetings hours for each goal
          const durationInHours = moment
            .duration(
              moment(event?.end?.dateTime).diff(moment(event?.start?.dateTime))
            )
            .asHours();
          goalItem.meetingHours = (
            parseFloat(goalItem.meetingHours) + durationInHours
          ).toFixed(1);
          goalItem.meetingHours = parseFloat(goalItem.meetingHours); // Parse as float

          //men hours for each goal
          const noOfAttendees = filterAttendees(event?.attendees).length || 0;
          goalItem.peopleHours = parseFloat(
            noOfAttendees * goalItem.meetingHours
          ).toFixed(1);
        }
      }
    });
    return split;
  } else {
    return [];
  }
};

export const fetchMeVsMeetingForMyOrg = async (user, duration) => {
  //! hardcoding pranavi's user id for now
  const q = query(
    doc(
      FirestoreDB,
      `users/LPSCRk7tvQO6HaWf2mME23MnKMD3/orgCalculations/meVsMeetingTime`
    )
  );
  const snapShot = await getDoc(q);
  const res = snapShot.data();

  const durations = {
    7: "7days",
    30: "28days",
    90: "91days",
  };
  const selectedDuration = durations[`${duration}`];
  console.log(res?.[`${selectedDuration}`]?.ExcludingSelf);
  return res?.[`${selectedDuration}`]?.ExcludingSelf;
};

export const getMyOrgInsights = async (id) => {
  try {
    let data = {};
    const snapShot = await getDocs(
      query(collection(FirestoreDB, `users/${id}/orgCalculations`))
      //! hardcoding pranavi's user id for now
      // query(
      //   collection(
      //     FirestoreDB,
      //     `users/LPSCRk7tvQO6HaWf2mME23MnKMD3/orgCalculations`
      //   )
      // )
    );
    snapShot.forEach((doc) => {
      data[doc.id] = doc.data();
    });
    return data;
  } catch (error) {
    console.error("Error while my org insights fetch: ", error);
    throw error;
  }
};

export const formatDuration = (duration) => {
  const durations = {
    7: "7days",
    30: "28days",
    90: "91days",
  };

  return durations[duration];
};

export const getPrev7Days = () => {
  const currentDayOfWeek = moment().day();

  // Define an array of day names
  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // Initialize an empty array to store the previous seven day names
  const previousSevenDays = [];

  // Loop to get the previous seven days
  for (let i = 1; i <= 7; i++) {
    // Calculate the index of the previous day
    const previousDayIndex = (currentDayOfWeek - i + 7) % 7;

    // Add the name of the previous day to the array
    previousSevenDays.push(dayNames[previousDayIndex]);
  }

  return previousSevenDays.reverse();
};
