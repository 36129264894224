import { ThemeProvider, createTheme, darken } from "@mui/material";
import MaterialReactTable from "material-react-table";
import moment from "moment";
import React, { useMemo } from "react";
import ReactQuill from "react-quill";

const status = {
  0: "low",
  1: "medium",
  2: "high",
};
const completion_status = {
  true: "completed",
  false: "pending",
};
const ActionsTable = ({ data }) => {
  const defaultMaterialTheme = createTheme();
  const columns = useMemo(
    () =>
      data
        ? [
            {
              header: "Title",
              size: 280,
              Cell: ({ row: { original } }) => {
                return (
                  <ReactQuill
                    theme="bubble"
                    placeholder="Type action item"
                    value={original.html}
                    readOnly
                    style={{
                      textDecoration: original.completed && "line-through",
                    }}
                    formats={[
                      "bold",
                      "italic",
                      "underline",
                      "color",
                      "background",
                      "link",
                      "code",
                      "mention",
                    ]}
                    modules={{
                      toolbar: false,
                    }}
                  />
                );
              },
              enableSorting: false,
            },
            {
              accessorKey: "effort",
              header: "Effort",
              Cell: ({ cell }) => `${status[cell.getValue()]}`,
            },
            {
              accessorKey: "priority",
              header: "Priority",
              Cell: ({ cell }) => `${status[cell.getValue()]}`,
            },
            {
              accessorKey: "completed",
              header: "Status",
              Cell: ({ cell }) => `${completion_status[cell.getValue()]}`,
            },
            {
              accessorFn: (row) =>
                moment(row.due_date).format("DD-MM-yyyy"),
              header: "Due Date",
              sortingFn: "datetime",
            },
            {
                accessorKey: "assigned.by",
                header: "Assigned By",
            }
          ]
        : [],
    [data]
  );
  return (
    <div className="card" style={{ flex: 2, overflow: "auto" }}>
      <ThemeProvider theme={defaultMaterialTheme}>
        <MaterialReactTable
          style={{ height: "10rem" }}
          columns={columns}
          data={data ? data : []}
          enableGrouping
          enableStickyHeader
          enableColumnOrdering
          enableFullScreenToggle={true}
          // onIsFullScreenChange={(message)=>console.log(message)}
          enableDensityToggle={false}
          initialState={{
            density: "compact",
            expanded: false, //expand all groups by default
            pagination: { pageIndex: 0, pageSize: 10 },
            // sorting: [{ id: "state", desc: false }] //sort by state by default
          }}
          muiTablePaperProps={{
            elevation: 0,
            sx: {
              borderRadius: "0",
              border: "none",
              width: "100%",
            },
          }}
          muiTableContainerProps={{
            sx: {
              maxHeight: "25rem",
              overflow: "auto",
              width: "100%",
            },
          }}
          muiTableProps={{
            sx: {
              tableLayout: "fixed",
            },
          }}
          muiTableBodyProps={{
            sx: (theme) => ({
              "& tr:nth-of-type(odd)": {
                backgroundColor: darken(theme.palette.background.default, 0.02),
              },
            }),
          }}
        />
      </ThemeProvider>
    </div>
  );
};

export default ActionsTable;
