import { Flex, Loader, Text, Title } from "@mantine/core";
import React, { useMemo } from "react";
import ReactECharts from "echarts-for-react";
import {
  getOrgMeetingDist,
  getPointsForEffDis,
  getXAxisForEffDis,
} from "utils/insights";
import { getPointsForMeetingDis } from "utils/insights";

const MeetingDistBySizeCard = ({ type, data, isLoading, isError, over, email, stackTotal=true }) => {
  // if type is organization then manipulating org details
  const formattedData = useMemo(
    () => type === "organization" && getOrgMeetingDist(email, data?.data),
    [data, email, type]
  );
  const option = useMemo(() => {
    const inputData =
      type !== "organization" ? data?.data?.bySize : formattedData?.bySize;
    if (data?.status) {
      const yAxis =
        over === "meetings"
          ? { type: "value" }
          : { type: "value", min: 0, max: 5 };

      const series = () => {
        // bars according to rating scores on y axis
        return [
          {
            name: `External ${over}`,
            type: "bar",
            stack: stackTotal && "total",
            data:
              over === "meetings"
                ? getPointsForMeetingDis(inputData, "external")
                : getPointsForEffDis(inputData, "external"),
            itemStyle: {
              color: "#fab005",
            },
          },
          {
            name: `Internal ${over}`,
            type: "bar",
            stack: stackTotal && "total",
            data:
              over === "meetings"
                ? getPointsForMeetingDis(inputData, "internal")
                : getPointsForEffDis(inputData, "internal"),
            itemStyle: {
              color: "#39a8f0",
            },
            show: false,
          },
        ];
      };

      return {
        xAxis: {
          data: getXAxisForEffDis(inputData),
          name: "Number of Attendees",
          nameLocation: "middle",
          nameTextStyle: {
            padding: 15,
          },
          axisLabel: {
            interval: 0,
          },
        },
        yAxis: {
          ...yAxis,
          name: over,
          nameLocation: "middle",
          nameTextStyle: {
            padding: 15,
          },
          axisLabel: {
            interval: 0,
          },
        },
        series: series(),
        grid: {
          top: "10%",
          right: "5%",
          bottom: "20%",
          left: "9%",
        },
        tooltip: {
          trigger: "axis",
        },
      };
    }
  }, [data?.data, data?.status, over, type, formattedData, stackTotal]);

  if (isLoading) {
    return (
      <Wrapper>
        <div className="loader">
          <Loader />
          <span>Fetching meeting distribution</span>
        </div>
      </Wrapper>
    );
  }
  if (isError || !data?.status) {
    return (
      <Wrapper>
        <div className="loader">
          {data?.message || <span>Error while fetching time distribution</span>}
        </div>
      </Wrapper>
    );
  }
  return (
    <Wrapper over={over}>
      <ReactECharts
        option={option}
        style={{ height: "100%", width: "100%", alignSelf: "center" }}
        opts={{ renderer: "svg" }}
      />
    </Wrapper>
  );
};

export default MeetingDistBySizeCard;

const Wrapper = ({ children, over }) => {
  return (
    <div className="card" style={{ height: "21rem" }}>
      <Flex direction={"column"} sx={{ width: "100%" }}>
        <Flex direction={"column"}>
          <Title order={5}>Distribution of {over}</Title>
          <Text c="dimmed" fz={"15px"}>
            {`over the size `}{" "}
          </Text>
        </Flex>
        {children}
      </Flex>
    </div>
  );
};
