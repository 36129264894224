import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  browserName,
  browserVersion,
  isChrome,
  isEdge,
  isEdgeChromium,
  isFirefox,
  isOpera,
  isSafari,
} from "react-device-detect";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { Avatar, Flex, Menu } from "@mantine/core";
import {
  AiOutlineLogout,
  AiOutlineShareAlt,
  AiOutlineUsergroupAdd,
} from "react-icons/ai";
import { MdOutlineExtension } from "react-icons/md";

import Logo from "assets/logo.svg";
import InviteModal from "components/InviteModal/InviteModal";
import WithPremium from "components/WithPremium/WithPremium";
import { signOut } from "firebase/auth";
import { useUser } from "stores/useUser";
import { notify } from "utils/common";
import { FirebaseAuth, FirestoreDB } from "utils/firebase";

import css from "./Header.module.scss";
import { CHROME_EXTENSION, FIREFOX_EXTENSION } from "./constants";
import ShareAccessDrawer from "components/ShareAccessDrawer/ShareAccessDrawer";

const Header = () => {
  const [inviteModalOpened, setInviteModalOpened] = useState(false);
  const navItems = ["workspace", "insights", "actions", "calendar", "events"];
  const { user, setUser } = useUser();
  const navigate = useNavigate();
  const [connCount, setConnCount] = useState(0);
  const [shareAccessOpened, setShareAccessOpened] = useState(false);
  const addBrowserExtension = () => {
    console.log(`${browserName} ${browserVersion}`);

    // isChrome, isFirefox, isSafari, isIE
    if (isChrome || isOpera || isEdge || isEdgeChromium) {
      notify("Adding browser extension...", "success");
      window.open(CHROME_EXTENSION.url, "_blank");
    } else if (isFirefox) {
      notify("This extension is not compatible for this browser !!", "error");
      window.location.href = FIREFOX_EXTENSION.url;
    } else if (isSafari) {
      notify("This extension is not compatible for this browser !!", "error");
      window.open(CHROME_EXTENSION.url, "_blank");
    }

    // Other props to check from the library
    // osName, osVersion // isWindows, isMacOs // MobileView, BrowserView, TabletView, AndroidView, IOSView
  };

  const inviteHandler = () => {
    //assuming now maximum connnections 15
    if (connCount < 15) {
      setInviteModalOpened(true);
    } else {
      notify("Your connections limit has been reached !!", "error");
    }
  };

  // here getting count of connections
  const getConnectionsCount = async () => {
    const q = query(
      collection(FirestoreDB, "users", user?.uid, "connections"),
      where("uid", "!=", null)
    );
    onSnapshot(q, (querySnapshot) => {
      setConnCount(querySnapshot.size);
    });
  };

  useEffect(() => {
    user?.subscription?.plan && getConnectionsCount();
  }, [user]);

  return (
    <header className={css.container}>
      <div className={css.logo} onClick={() => navigate("/workspace")}>
        <img src={Logo} alt="" />
        <span>Gain</span>
        <span>Time</span>
      </div>

      <ul className={css.menu}>
        {navItems.map((item, i) => (
          <li key={i}>
            {item === "insights" ? (
              <WithPremium feature={"insights"}>
                <NavLink
                  to={`/${item}`}
                  className={({ isActive }) =>
                    isActive ? css.active : undefined
                  }
                >
                  {item}
                  <div className="tab-bottom-line" />
                </NavLink>
              </WithPremium>
            ) : (
              <NavLink
                to={`/${item}`}
                className={({ isActive }) =>
                  isActive ? css.active : undefined
                }
              >
                {item}
                <div className="tab-bottom-line" />
              </NavLink>
            )}
          </li>
        ))}
      </ul>

      <Menu>
        <div className={css.profileContainer}>
          <Menu.Target>
            <Flex gap={"1rem"}>
              <span>{user.displayName.split(" ")[0]}</span>
              <Avatar
                src={user?.photoURL}
                alt="profile img"
                radius="xl"
                size={"28px"}
              />
            </Flex>
          </Menu.Target>
          <Menu.Dropdown>
            {user?.subscription?.plan.toUpperCase() === "INDIVIDUAL" && (
              <Menu.Item
                icon={<AiOutlineUsergroupAdd size="20px" />}
                onClick={inviteHandler}
              >
                Invite others
              </Menu.Item>
            )}
            <Menu.Item
              icon={<MdOutlineExtension size="20px" />}
              onClick={addBrowserExtension}
            >
              Add Extension
            </Menu.Item>
            <Menu.Item
              icon={<AiOutlineShareAlt size={20} />}
              onClick={() => setShareAccessOpened(true)}
            >
              <span>Share Actions access</span>
            </Menu.Item>
            <Menu.Item
              icon={<AiOutlineLogout size="20px" />}
              onClick={() => {
                signOut(FirebaseAuth)
                  .then(async () => {
                    await Promise.resolve(setUser(null));
                    navigate("/");
                  })
                  .catch((error) => {
                    notify("an error happened while signing out", "error");
                    console.log(error);
                  });
              }}
            >
              Logout
            </Menu.Item>
          </Menu.Dropdown>
        </div>
        {/* modal component */}
        <InviteModal
          opened={inviteModalOpened}
          setOpened={setInviteModalOpened}
        />
      </Menu>
      <ShareAccessDrawer
        opened={shareAccessOpened}
        setOpened={setShareAccessOpened}
      />
    </header>
  );
};

export default Header;
