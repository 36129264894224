import React, { useState } from "react";
import { Popover, Flex, Badge, Text, Select } from "@mantine/core";
import { AiFillTag } from "react-icons/ai";
import { truncate, uniq } from "lodash";
import Tooltip from "components/Tooltip/Tooltip";
import { useClickOutside } from "@mantine/hooks";
import TagSelectBox from "./TagSelectBox";

const MeetingTagsDropdownNew = ({ tagOptions, handleOnClick, currentTag }) => {
  const [opened, setOpened] = useState(false);
  const ref = useClickOutside(() => setOpened(false));
  return (
    <div ref={ref}>
      <Popover
        width={300}
        trapFocus
        position="bottom"
        withArrow
        shadow="md"
        opened={opened}
      >
        <Popover.Target>
          <Flex onClick={() => setOpened((o) => !o)}>
            <Badge
              size="lg"
              sx={{
                width: "8rem",
                cursor: "pointer",
                maxWidth: "16rem",
                color: "black",
              }}
              bg={currentTag && "#d9fad8"}
            >
              <Tooltip
                label={`${currentTag ? currentTag : "Select meeting type"}`}
                style={{ textTransform: "lowercase" }}
              >
                <Flex align={"center"} justify={"center"} gap={".5rem"}>
                  <AiFillTag size={17} />
                  <Text sx={{ textTransform: "lowercase" }} fw={"500"}>
                    {truncate(currentTag ? currentTag : "Meeting type", {
                      length: 10,
                    })}
                  </Text>
                </Flex>
              </Tooltip>
            </Badge>
          </Flex>
        </Popover.Target>
        <Popover.Dropdown>
          <TagSelectBox
            tagOptions={tagOptions}
            handleOnClick={handleOnClick}
            setOpened={setOpened}
          />
        </Popover.Dropdown>
      </Popover>
    </div>
  );
};

export default MeetingTagsDropdownNew;
