import { Flex, Grid, Skeleton, Stack } from "@mantine/core";
import React from "react";

const ActionsSummarySkelton = ({ isError }) => {
  if (isError) {
    <span>Error occured while fetching summary</span>;
  }
  return (
    <Grid>
      {Array(4)
        .fill(0)
        .map((_, i) => (
          <Grid.Col span={3} sx={{ height: "15rem" }} key={i}>
            <Skeleton height={"70%"} mt={6} radius="md" />
            <Skeleton height={"20%"} mt={6} radius="md" />
          </Grid.Col>
        ))}
      <Grid.Col span={6} sx={{ height: "15rem" }}>
        <Skeleton height={"30%"} mt={6} radius="md" />
        <Skeleton height={"30%"} mt={6} radius="md" />
        <Skeleton height={"30%"} mt={6} radius="md" />
      </Grid.Col>
      <Grid.Col span={6} sx={{ height: "15rem" }}>
        <Flex gap={"2rem"} align={"center"} h={"100%"} w={"100%"}>
          <Skeleton height={80} circle />
          <Stack w={"70%"} h={"100%"} justify="flex-end">
            <Skeleton height={30} mt={6} radius="md" />
            <Skeleton height={30} mt={6} radius="md" />
            <Skeleton height={30} mt={6} radius="md" />
          </Stack>
        </Flex>
      </Grid.Col>
      <Grid.Col span={12} sx={{ heigh: "25rem" }} mt={24}>
        <Stack>
          {Array(3)
            .fill(0)
            .map((_, i) => (
              <Skeleton height={30} mt={6} radius="md" key={i}/>
            ))}
        </Stack>
      </Grid.Col>
    </Grid>
  );
};

export default ActionsSummarySkelton;
