export const ENTERPRISE_USERS = [
  {
    email: 'cmcintyre@marqeta.com',
    finchId: '0pJHWN-WKA8-9IX0-7qMo-7ebjrhcomN'
  },
  {
    email:"asholomytskyy@marqeta.com",
    finchId: "05otjf-GVcN-XUkX-uc2P-EyArqmMua7"
  },
  {
    email: 'tsingh@marqeta.com',
    finchId: '1CQQbj-2gqC-IGgf-xhad-GGKmzyk5wf'
  },
  {
    email: 'oespaillat@marqeta.com',
    finchId: '4Hrfzf-TRyp-bqGs-028Z-mutAG10NSV'
  },
  {
    email: 'rgraziosi@marqeta.com',
    finchId: '4qZwse-tJID-YjiX-lX74-YU4OsJlcZT'
  },
  {
    email: 'mfrankhuff@marqeta.com',
    finchId: '5TU45F-1C9X-Ku7Y-26Hk-3riRseE7Ub'
  },
  {
    email: 'tpress@marqeta.com',
    finchId: '62LcpW-IQ8B-SOo4-Ws8G-QEoLb8SOqs'
  },
  {
    email: 'rshah@marqueta.com',
    finchId: '68uuFN-djg4-PY2M-TffJ-uIjUwqwNG4'
  },
  {
    email: 'cironside@marqeta.com',
    finchId: '8qC5fQ-QaEZ-h8sC-xOBo-92BcWkBFCJ'
  },
  {
    email: 'spitts@marqeta.com',
    finchId: '9nFi0p-8zCO-TmIl-ea3z-WwSaoq46Ld'
  },
  {
    email: 'shauber@marqeta.com',
    finchId: '9gCKW8-B8Gj-qdAR-0vFQ-BjUakjAlQS'
  },
  {
    email: "kchatterjee@marqeta.com",
    finchId: "9wFiCg-6zxI-76h3-gsza-ylrqliVF2W"
  },
  {
    email: "rleyva@marqeta.com",
    finchId: "1xqcM6-iWns-3LhO-X8CQ-D9X7g6GxYc"
  },
  {
    email: "jfoggiato@marweta.com",
    finchId: "3sYrwC-Jlv4-XSF9-miBW-R3rREEfVgE"
  },
  {
    email: "rahulshahtester@gmail.com",
    finchId: "3vHHDf-KQXo-WWYl-vCy5-GxEYSldIeY"
  },
  {
    email: "kjoshi@marqeta.com",
    finchId: "8T05Qz-LXSH-swXr-pouP-ReFG73yChb"
  },
  {
    email: "sraut@marqeta.com",
    finchId: "3TfozQ-B3OP-qsDE-lAEe-dfUwYdo7er"
  }
];

export const COMPANY_NAME = "Marqueta";
