import { create } from "zustand";
const useAnalyticsStore = create((set) => ({
  duration: 7,
  setDuration: (value) =>
    set(() => ({
      duration: value,
    })),
    organizationMeetingTables : {},
    setOrganizationMeetingTables: (value)=> 
    set(()=> ({
      organizationMeetingTables: value
    }))
}));

export default useAnalyticsStore