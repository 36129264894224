import { Button, Flex, Text } from "@mantine/core";
import moment from "moment";
import React, { useEffect } from "react";
import { IoCaretBackOutline, IoCaretForwardOutline } from "react-icons/io5";

const DateHandler = ({
  activeDateRange,
  setActiveDateRange,
  scrollIntoView,
}) => {
  const startDateDay = moment(activeDateRange?.startDate).format("DD");
  const endDateDay = moment(activeDateRange?.endDate)
    .subtract(1, "day")
    .format("DD");
  const startDateMonth = moment(activeDateRange?.startDate).format("MMMM");
  const endDateMonth = moment(activeDateRange?.endDate).format("MMMM");
  const startDateYear = moment(activeDateRange?.startDate).format("YYYY");
  const endDateYear = moment(activeDateRange?.endDate).format("YYYY");

  const defaultDateRange = () => {
    var currentDate = moment();
    var startDate = currentDate.clone().startOf("week").format();
    var endDate = currentDate.clone().endOf("week").add(1, "day").format();
    setActiveDateRange({ startDate, endDate });
  };
  useEffect(() => {
    defaultDateRange();
  }, []);

  const handleCaretClick = (direction) => {
    const currentDate = moment(activeDateRange.startDate);
    let startDate, endDate;

    if (direction === "back") {
      startDate = currentDate
        .clone()
        .subtract(1, "week")
        .startOf("week")
        .format();
      endDate = currentDate
        .clone()
        .subtract(1, "week")
        .endOf("week")
        .add(1, "day")
        .format();
    } else if (direction === "forward") {
      startDate = currentDate.clone().add(1, "week").startOf("week").format();
      endDate = currentDate
        .clone()
        .add(1, "week")
        .endOf("week")
        .add(1, "day")
        .format();
    }

    setActiveDateRange({ startDate, endDate });
  };

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <Button
        onClick={() => {
          defaultDateRange();
          scrollIntoView({
            alignment: "center",
          });
        }}
        sx={{ position: "absolute", left: "0rem", top: "-0.5rem" }}
      >
        Today
      </Button>
      <Flex
        sx={{ fontSize: "1.2rem", fontWeight: 500 }}
        align={"center"}
        gap={".7rem"}
        margin="auto"
        w={"100%"}
        justify={"center"}
      >
        <IoCaretBackOutline
          color="#39a8f0"
          size={23}
          cursor={"pointer"}
          onClick={() => handleCaretClick("back")}
        />
        <Text>
          {startDateMonth} {startDateDay}
        </Text>
        {startDateYear !== endDateYear && (
          <Text c={"dimmed"}>, {startDateYear}</Text>
        )}
        <Text> - </Text>
        {startDateMonth !== endDateMonth && <Text>{endDateMonth}</Text>}
        <Text>{endDateDay}</Text>
        <Text c={"dimmed"}>, {endDateYear}</Text>
        <IoCaretForwardOutline
          color="#39a8f0"
          size={23}
          cursor={"pointer"}
          onClick={() => handleCaretClick("forward")}
        />
      </Flex>
    </div>
  );
};

export default DateHandler;
