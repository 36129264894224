import {
  getCostOfUserMeetings,
  getIndividualRating,
  getNoAndHoursOfMeetings,
} from "api";
import { useQuery } from "react-query";
import { useUser } from "stores/useUser";
import useMeetingDistribution from "./useMeetingDistribution";
import useMeTimeMeetingDetails from "./useMeTimeMeetingDetails";

const usePrefetch = ({ source }) => {
  const {
    user: {
      email,
      uid,
        subscription : {
            organization_id
        }
    },
  } = useUser();
  const enabled = source !== "extension";
  //   ---------- as attendee quereies --------- //
  useQuery({
    queryKey: ["cost", "attendee", email, 7],
    queryFn: () => getCostOfUserMeetings(email, "attendee", 7),
    staleTime: 300000, // 5 minutes
    enabled,
  });

  useMeetingDistribution({
    userEmail: email,
    type: "attendee",
    duration: 7,
    enabled,
  });

  useQuery({
    queryKey: ["hours", "individual", "attendee", email, null, null],
    queryFn: () =>
      getNoAndHoursOfMeetings("individual", "attendee", email, null, null),
    staleTime: 300000,
    enabled,
  });

  useQuery({
    queryKey: ["Effectiveness Compare", email, "attendee"],
    queryFn: () => getIndividualRating(email),
    staleTime: 5 * (60 * 1000), //5 minutes
    enabled,
  });



  //   ---------- as organizer quereies --------- //
  useQuery({
    queryKey: ["cost", "organizer", email, 7],
    queryFn: () => getCostOfUserMeetings(email, "organizer", 7),
    staleTime: 300000, // 5 minutes
    enabled,
  });

  useMeetingDistribution({
    userEmail: email,
    type: "organizer",
    duration: 7,
    enabled,
  });

  useQuery({
    queryKey: ["hours", "individual", "organizer", email, null, null],
    queryFn: () =>
      getNoAndHoursOfMeetings("individual", "organizer", email, null, null),
    staleTime: 300000,
    enabled,
  });

  useQuery({
    queryKey: ["Effectiveness Compare", email, "organizer"],
    queryFn: () => getIndividualRating(email),
    staleTime: 5 * (60 * 1000), //5 minutes
    enabled,
  });

  //   ---------- me time page quereies --------- //
  useMeTimeMeetingDetails({
    userEmail: email,
    organizationId:  organization_id,
    duration: 7,
    enabled
  });
  return null;
};

export default usePrefetch;
