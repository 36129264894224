import { fetchMeetingDisForUser } from "api";
import { useQuery } from "react-query";

const useMeetingDistribution = ({userEmail, type, duration, enabled}) => {

    const { data, isLoading, isError } = useQuery({
        queryKey: ["Meeting Distribution", userEmail, type, duration],
        queryFn: () => fetchMeetingDisForUser(userEmail, duration, type),
        staleTime: 5 * 60 * 1000,
        enabled
      });
  return (
   {data, isLoading, isError}
  )
}

export default useMeetingDistribution