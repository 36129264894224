import React from "react";
import css from "./EffectivenessCard.module.scss";
import { Flex, Loader, Rating, Text, Title } from "@mantine/core";
import ReactECharts from "echarts-for-react";
import { FiInfo } from "react-icons/fi";
import Tooltip from "components/Tooltip/Tooltip";
const EffectivenessCard = ({
  type,
  isLoading,
  isError,
  data,
  userEmail,
  label = "Effectiveness",
}) => {
  const getData = () => {
    if (!isLoading && data && !data.message) {
      return [
        data?.ratings["1 star"].count,
        data?.ratings["2 star"].count,
        data?.ratings["3 star"].count,
        data?.ratings["4 star"].count,
        data?.ratings["5 star"].count,
      ];
    }
  };

  const getPercentage = () => {
    if (!isLoading && data && !data.message) {
      return [
        data?.ratings["1 star"]?.percentage,
        data?.ratings["2 star"]?.percentage,
        data?.ratings["3 star"]?.percentage,
        data?.ratings["4 star"]?.percentage,
        data?.ratings["5 star"]?.percentage,
      ];
    }
  };
  const getChartOption = () => ({
    xAxis: {
      max: "dataMax",
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      splitLine: {
        show: false, // Hide the lines between the ticks
      },
      axisLabel: {
        show: false, // Hide the tick labels
      },
    },
    yAxis: [
      {
        type: "category",
        data: ["1 star", "2 star", "3 star", "4 star", "5 star"],
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
      {
        type: "category",
        data: getPercentage()?.map(
          (value) => `${parseFloat(value).toFixed(1)}%`
        ),
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
    ],
    series: [
      {
        type: "bar",
        data: getData(),
        label: {
          show: false,
          position: "right",
        },
        barWidth: 10,
        itemStyle: {
          normal: {
            color: "#FFC107",
            borderRadius: [20, 20, 20, 20],
          },
        },
      },
    ],
    legend: {
      show: false,
    },
    grid: {
      height: "120px",
      right: "15%",
      bottom: "25%",
      left: "15%",
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
  });

  if (isLoading) {
    return (
      <EffectivenessCardWrapper type={type} data={data} label={label}>
        <div className="loader">
          <Loader />
          <span>Fetching Effectiveness score</span>
        </div>
      </EffectivenessCardWrapper>
    );
  }

  if (isError) {
    return (
      <EffectivenessCardWrapper type={type} data={data} label={label}>
        <div className="loader">
          <span>Error while fetching data</span>
        </div>
      </EffectivenessCardWrapper>
    );
  }

  if (data?.message) {
    return (
      <EffectivenessCardWrapper type={type} data={data} label={label}>
        <div className="loader">
          <span>Have no meetings</span>
        </div>
      </EffectivenessCardWrapper>
    );
  }

  const effectivenessAverageRating = parseFloat(data?.totalAverageOfRatings).toFixed(1);
  const renderedEffectivenessAverageRating = isNaN(effectivenessAverageRating) ? "-" : effectivenessAverageRating
  return (
    <EffectivenessCardWrapper type={type} data={data} label={label}>
      <div className={css.chart} style={{ zIndex: -1 }}>
        <ReactECharts option={getChartOption()} />
      </div>

      <div className={css.detail}>
        <div>
          <Title order={1}>
            {renderedEffectivenessAverageRating}
          </Title>
          <div>
            <Rating
              value={renderedEffectivenessAverageRating}
              fractions={2}
              readOnly
            />
            <Text fz="sm">Average Rating</Text>
          </div>
        </div>
        <div>
          <Text fz={"md"}>
            <Text fz={"lg"} display={"inline"} fw={"bold"} color="grey">
              {type === "organizer"
                ? data?.totalRatings
                : type === "myOrg"
                ? data?.totalAttendeesRated
                : data?.totalSumOfRatings}
            </Text>{" "}
            ratings
          </Text>
        </div>
      </div>
    </EffectivenessCardWrapper>
  );
};

export default EffectivenessCard;

const EffectivenessCardWrapper = ({ type, children, label }) => {
  return (
    <div className={`card ${css.container}`}>
      <Flex justify={"space-between"} align={"center"} style={{ zIndex: 1 }}>
        <Title order={5}>
          {type === "organizer"
            ? "Effectiveness of organized"
            : type === "attendee"
            ? "You rated"
            : label}
        </Title>
        <Tooltip label="Explanation of how it works">
          <div>
            <FiInfo size={16} color="grey" />
          </div>
        </Tooltip>
      </Flex>
      {children}
    </div>
  );
};
