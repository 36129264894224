import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { Flex, Loader, Title } from "@mantine/core";
import useAnalyticsStore from "stores/useAnalyticsStore";
import { useUser } from "stores/useUser";
import { notify } from "utils/common";
import { FirestoreDB } from "utils/firebase";
import {
  collection,
  documentId,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { getRatingPoints, getXAxis } from "utils/insights";
import moment from "moment";

const EffectivenessLineCardForOrganization = () => {
  const { duration } = useAnalyticsStore();
  const { user } = useUser();
  const [orgData, setOrgData] = useState(null);
  const [fireBaseError, setFirebaseError] = useState(null);
  const [loading, setLoading] = useState(null);
  useEffect(() => {
    const getOrgData = async () => {
      if (!user?.subscription?.organization_id) {
        notify("User is not the part of any organization", "error");
        return;
      }
      try {
        setLoading(true);
        const q = query(
          collection(FirestoreDB, `averageRatings`),
          where(documentId(), "==", user?.subscription?.organization_id)
        );
        const querySnapshot = await getDocs(q);
        let orgDataTemp = [];
        querySnapshot.forEach((doc) => {
          orgDataTemp.push(doc.data());
        });
        if (orgDataTemp.length === 0) {
          setFirebaseError("No Organization found!");
        } else setOrgData(orgDataTemp[0]);
      } catch (e) {
        console.log(e);
        notify("Unable to fetch organization details", "error");
        setFirebaseError("Unable to fetch organization details");
      } finally {
        setLoading(false);
      }
    };
    getOrgData();
  }, [user]);

  const option = React.useMemo(() => {
    return {
      xAxis: {
        data:
          duration === 7
            ? getXAxis(duration).map((date) => moment(date).format("ddd"))
            : getXAxis(duration),
        axisLabel: {
          interval: 0,
          rotate: duration === 90 ? -45 : 0,
        },
      },
      yAxis: {},
      series: [
        {
          name: "As organizer",
          type: "line",
          data: getRatingPoints("organizer", duration, orgData),
          itemStyle: {
            color: "#39a8f0",
          },
        },
        {
          name: "As attendee",
          type: "line",
          data: getRatingPoints("attendee", duration, orgData),
          itemStyle: {
            color: "#fab005",
          },
        },
      ],
      grid: {
        top: "15%",
        right: "10%",
        bottom: "25%",
        left: "5%",
      },
      tooltip: {
        trigger: "axis",
      },
    };
  }, [orgData, duration]);


  if (fireBaseError) {
    return (
      <Wrapper>
        <div className="loader">
          <span>{fireBaseError}</span>
        </div>
      </Wrapper>
    );
  }
  if (loading) {
    return (
      <Wrapper>
        <div className="loader">
          <Loader />
          <span>Comparing average ratings</span>
        </div>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <ReactECharts
        option={option}
        style={{ height: "100%", width: "100%" }}
        opts={{ renderer: "svg" }}
      />
    </Wrapper>
  );
};

export default EffectivenessLineCardForOrganization;

const Wrapper = ({ children }) => {
  return (
    <div className="card">
      <Flex direction={"column"} sx={{ width: "100%", height: '12rem' }}>
        <Title order={5}>Effectiveness over time</Title>
        {children}
      </Flex>
    </div>
  );
};
